<template>
  <div class="dashboard">
    <v-card min-width="40%">
      <v-card-title>User Roles</v-card-title>
      <v-card-subtitle>{{ email }}</v-card-subtitle>
      <v-card-actions>
        <v-btn color="error" @click="$router.go(-1)">
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card min-width="95%">
      <v-data-table :headers="headers" :items="userRoles">
        <template v-slot:item.action="{ item }">
          <v-btn color="error"
            @click="openDeleteWarning(item.tenant, item.unit, item.role)">
            Delete
            <v-icon right>mdi-account-cancel</v-icon>
          </v-btn>
        </template>
        <template v-slot:body.prepend>
          <tr>
            <td>
              <v-combobox label="Tenant" v-model="selectedTenant" :items="tenants"/>
            </td>
            <td>
              <v-combobox label="Unit" v-model="selectedUnit" :items="units"/>
            </td>
            <td>
              <v-combobox label="Role" v-model="selectedRole" :items="roles"/>
            </td>
            <td>
              <v-btn :disabled="!canAddUserRole()" @click="addUserRole()">
                Add
                <v-icon right>mdi-account</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="deleteWarning"
      persistent
      max-width="340px">
      <v-card>
        <v-card-title class="headline">Warning!</v-card-title>
        <v-card-text>
          <p>Are you sure?</p>
          <p>This action is permanent</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error"
            @click="deleteWarning = false">
            No
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            @click="deleteUserRole(tenantToBeDeleted, unitToBeDeleted, roleToBeDeleted)">
            Yes
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: 'Tenant',
          value: 'tenant',
        },
        {
          text: 'Unit',
          value: 'unit',
        },
        {
          text: 'Role',
          value: 'role',
        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
          width: '100px',
        },
      ],
      email: null,
      tenants: [],
      units: [],
      roles: [],
      userRoles: [],
      selectedTenant: null,
      selectedUnit: null,
      selectedRole: null,
      tenantToBeDeleted: null,
      unitToBeDeleted: null,
      roleToBeDeleted: null,
      deleteWarning: false,
    };
  },
  methods: {
    canAddUserRole() {
      return this.selectedTenant !== null && this.selectedTenant.trim() !== ''
        && this.selectedUnit !== null && this.selectedUnit.trim() !== ''
        && this.selectedRole !== null && this.selectedRole.trim() !== '';
    },
    async addUserRole() {
      await this.$http.post(`/api/v2/user/${encodeURIComponent(this.email)}/role/assign`,
        { tenant: this.selectedTenant, unit: this.selectedUnit, role: this.selectedRole });
      this.$toast.success('User role added');
      this.selectedRole = null;
      await this.listUserRoles();
    },
    async deleteUserRole(tenant, unit, role) {
      await this.$http.post(`/api/v2/user/${encodeURIComponent(this.email)}/role/unassign`,
        { tenant, unit, role });
      this.$toast.success('User role deleted');
      this.tenantToBeDeleted = null;
      this.unitToBeDeleted = null;
      this.roleToBeDeleted = null;
      this.deleteWarning = false;
      await this.listUserRoles();
    },
    async listUserRoles() {
      const response = await this.$http.get(`/api/v2/user/${encodeURIComponent(this.email)}/role`);
      const userRoles = Object.keys(response.data)
        .map(t => Object.keys(response.data[t])
          .map(u => response.data[t][u]
            .map(r => ({ tenant: t, unit: u, role: r }))));
      this.userRoles = userRoles.flat(2);
    },
    async listTenants() {
      const response = await this.$http.get('/api/v2/user/tenant');
      this.tenants = response.data;
    },
    async listUnits() {
      const response = await this.$http.get('/api/v2/user/unit');
      this.units = response.data;
    },
    async listRoles() {
      const response = await this.$http.get('/api/v2/user/role');
      this.roles = response.data;
    },
    openDeleteWarning(tenant, unit, role) {
      this.deleteWarning = true;
      this.tenantToBeDeleted = tenant;
      this.unitToBeDeleted = unit;
      this.roleToBeDeleted = role;
    },
  },
  created() {
    this.email = decodeURIComponent(this.$route.params.email);
  },
  mounted() {
    this.listUserRoles();
    this.listTenants();
    this.listUnits();
    this.listRoles();
  },
};
</script>

<style scoped>
div.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-dialog div.v-card {
  margin-bottom: 0px;
}
div.v-dialog div.v-card div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card {
  margin-bottom: 10px;
}
button.v-btn {
  margin-left: 1px;
  margin-right: 1px;
}
</style>
