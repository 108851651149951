<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title>{{ label }}</v-card-title>
      <v-card-text>
          <div v-show="!isLabelling">Are you sure?</div>
          <div v-if="code">Please capture the following code to confirm:</div>
          <div v-if="code">{{ generatedCode }}</div>
          <v-text-field
            v-if="code"
            v-model="capturedCode"
            @keyup.enter="confirm()"/>
          <div v-if="isLabelling">
            <p class="red--text">Seems there may be datums still in labelling.</p>
            <p>Press 'OK' to delete anyway</p>
            <p>or 'Cancel' to escape</p>
          </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="cancel"
          color="error"
        >
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="confirm"
          color="primary"
          :disabled="code && !codeMatches"
        >
          OK
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      showing: false,
      generatedCode: '',
      capturedCode: null,
    };
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      required: false,
    },
    code: {
      type: Boolean,
      required: false,
    },
    isLabelling: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    codeMatches() {
      return this.generatedCode === this.capturedCode;
    },
  },
  methods: {
    confirm() {
      if (!this.code || this.codeMatches) {
        this.showing = false;
        this.$emit('ok');
      }
    },
    cancel() {
      this.showing = false;
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      deep: true,
      handler(show) {
        this.showing = show;
        if (show) {
          this.generatedCode = `${Math.floor((Math.random() * 9000) + 1001)}`;
          this.capturedCode = null;
        }
      },
    },
  },
};
</script>
<style scoped>
.pad {
  margin-bottom: 15px;
}
div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: left;
}
div.v-card__text div {
  margin: 5px;
}
</style>
