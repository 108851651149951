<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row justify="end" class="mr-5 mt-2">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Add Project
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="headline">
          Add New Project
        </v-card-title>
            <v-card-text>
              <v-layout wrap align-center>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                    v-model="projectName"
                    outlined
                    label="Project Name">
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                    v-model="description"
                    outlined
                    label="Project Description">
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-select
                    :items="fileTypes"
                    v-model="fileType"
                    outlined
                    label="File Type"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="dialog = false"
          >
            Cancel
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disable()"
            @click="close"
          >
           Add
           <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      projectName: '',
      description: '',
      fileType: '',
      fileTypes: ['filled_image', 'template_image'],
    };
  },
  computed: {
    projectNames() {
      return this.$props.projects.map(project => project.project_name);
    },
  },
  methods: {
    close() {
      const projectFields = {
        project_name: this.projectName,
        description: this.description,
        file_type: this.fileType,
      };
      this.$emit('addProject', projectFields);
      this.dialog = false;
      this.clear();
    },
    clear() {
      this.projectName = '';
      this.description = '';
      this.fileType = '';
    },
    disable() {
      if (this.projectNames.includes(this.projectName)) { return true; }
      if (this.projectName === '') { return true; }
      if (this.description === '') { return true; }
      if (this.fileType === '') { return true; }
      return false;
    },
  },
};
</script>
