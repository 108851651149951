<template>
  <div class="dashboard">
    <v-card min-width="40%">
      <v-card-title>Reset Password</v-card-title>
      <v-card-subtitle>{{ email }}</v-card-subtitle>
      <v-card-text>
        <v-text-field
          type="password"
          v-if="sameUser()"
          v-model="oldPassword"
          ref="oldPassword"
          label="Old Password"
        />
        <v-text-field
          type="password"
          v-model="newPassword"
          ref="newPassword"
          label="New Password"
        />
        <v-text-field
          type="password"
          v-model="confirmPassword"
          ref="confirmPassword"
          label="Confirm Password"
        />
        <div style="display: inline-block; vertical-align: bottom;">
          <div>Password Strength</div>
          <div class="strength">
            <div :class='strengthClass()'></div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="cancel">
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn @click="reset" :disabled="!canReset()">
          Reset
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios';
import zxcvbn from 'zxcvbn';
import * as security from '@/services/SecurityService';

export default {
  data() {
    return {
      email: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    };
  },
  methods: {
    canReset() {
      return this.newPassword && this.newPassword === this.confirmPassword;
    },
    strength() {
      return zxcvbn(this.newPassword || '').score;
    },
    strengthClass() {
      return `strength-${this.strength()}`;
    },
    sameUser() {
      return this.user().email === this.email;
    },
    user() {
      return security.default().getUser();
    },
    async reset() {
      try {
        let hmac = null;
        if (this.sameUser()) {
          const clientId = `local.${this.email}`;
          let clientSecret = this.oldPassword;
          hmac = null;
          if (security.default().maskSupported()) {
            clientSecret = security.default().mask(clientSecret);
            hmac = true;
          }

          const credential = {
            client_id: clientId,
            client_secret: clientSecret,
            grant_type: 'client_credentials',
            audience: security.default().options.local.audience,
          };
          if (hmac) {
            credential.hmac = true;
          }
          await axios.post('/api/v2/oauth/token', credential);

          let password = this.newPassword;
          hmac = null;
          if (security.default().maskSupported()) {
            password = security.default().mask(password);
            hmac = true;
          }
          await axios.post('/api/v2/user/reset_own_credential', {
            email: this.email,
            password,
            hmac,
          });
        } else {
          let password = this.newPassword;
          hmac = null;
          if (security.default().maskSupported()) {
            password = security.default().mask(password);
            hmac = true;
          }
          await axios.post('/api/v2/user/reset_credential', {
            email: this.email,
            password,
            hmac,
          });
        }
        this.$toast.success('Password reset');
        this.$router.go(-1);
      } catch (ex) {
        switch (ex.response.status) {
          case 401:
            this.$toast.error('Invalid password');
            break;
          default:
            this.$toast.error(ex.response.error);
            break;
        }
      }
    },
    async cancel() {
      this.$router.go(-1);
    },
  },
  created() {
    this.email = decodeURIComponent(this.$route.params.email);
  },
  mounted() {
    if (!this.sameUser()) {
      this.$refs.newPassword.focus();
    } else {
      this.$refs.oldPassword.focus();
    }
  },
};
</script>
<style scoped>
div.strength {
  background-color: white;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  width: 300px;
  height: 10px;
  padding: 1px;
}
div.strength-0 {
  background-color: darkred;
  width: 20%;
  height: 100%;
}
div.strength-1 {
  background-color: red;
  width: 40%;
  height: 100%;
}
div.strength-2 {
  background-color: orangered;
  width: 60%;
  height: 100%;
}
div.strength-3 {
  background-color: gold;
  width: 80%;
  height: 100%;
}
div.strength-4 {
  background-color: green;
  width: 100%;
  height: 100%;
}
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
