<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700"
    >
      <v-card v-if="project !== null">
        <v-card-title class="headline">
          Edit Project
        </v-card-title>
            <v-card-text>
              <v-layout wrap align-center>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                    v-model="project.project_name"
                    outlined
                    label="Project Name"
                    :disabled="true"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                      v-model="project.description"
                      outlined
                      label="Project Description">
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-select
                   :items="fileTypes"
                    v-model="project.file_type"
                    outlined
                    label="File Type"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="close"
          >
            Cancel
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disable()"
            @click="update"
          >
           Update
           <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      fileTypes: ['filled_image', 'template_image'],
      project: null,
      ProjectName: '',
    };
  },
  methods: {
    update() {
      const updateFields = {
        project_name: this.project.project_name,
        description: this.project.description,
        file_type: this.project.file_type,
      };
      this.$emit('updateProject', updateFields);
      this.dialog = false;
    },
    close() {
      this.dialog = false;
      this.project = JSON.parse(JSON.stringify(this.item));
    },
    disable() {
      /* eslint-disable */
      if (JSON.stringify(this.project) === JSON.stringify(this.$props.item)) { return true; }
      if (this.project.project_name === '') { return true; }
      if (this.project.description === '') { return true; }
      if (this.project.file_type === '') { return true; }
      return false;
    },
  },
  watch: {
    item() {
      this.project = JSON.parse(JSON.stringify(this.item));
      this.dialog = true;
    },
  },
};
</script>
