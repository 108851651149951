<template>
  <div class="dashboard">
    <v-card flat outlined min-width="40%">
      <v-card-title class="headline">
        Access denied
      </v-card-title>
      <v-card-text>
        <p>You do not have access to this resource.</p>
        <p>Click <router-link to="/">here</router-link> to return to the main page.</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
};
</script>
<style scoped>
div.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
