import axios from 'axios';
import Vue from 'vue';
import VueHotkey from 'v-hotkey';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  timeout: 2000,
});

Vue.prototype.$http = axios;

Vue.config.errorHandler = (err, vm) => {
  console.error(err);
  if (err.response !== undefined) {
    vm.$toast.error(err.response.data);
  } else {
    vm.$toast.error(err.toString());
  }
};

Vue.use(VueHotkey);

Vue.config.productionTip = false;

// Add Instance Properties with $ convention
Vue.prototype.$appName = 'Curated Database';

new Vue({
  router,
  vuetify,
  beforeCreate: function beforeCreate() {
    console.log(`${this.$appName} is starting...`);
  },
  render: h => h(App),
}).$mount('#app');
