<template>
  <div class="dashboard">
    <v-card flat outlined>
      <v-card-title class="headline">
        A technical error has occurred
      </v-card-title>
      <v-card-text>
        <p>Your browser requests to the server may be subjected to policies
        defined by your IT administrator, or may be being intercepted by a third party.</p>
        <p>Click <router-link to="/">here</router-link> to return to the main page.</p>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
};
</script>
<style scoped>
div.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
