<template>
  <div class="dashboard">
    <v-card outlined flat min-width="40%">
      <v-card-title class="headline">
        Signed Out
      </v-card-title>
      <v-card-text>
        <p>You have been signed out.</p>
        <p>Click <router-link to="/">here</router-link> to sign in again.</p>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as security from '@/services/SecurityService';

export default {
  beforeCreate() {
    security.default().signOut();
  },
};
</script>
<style scoped>
div.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
