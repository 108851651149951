<template>
  <div class="dashboard">
    <v-card flat >
      <v-card-text>
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as security from '@/services/SecurityService';

export default {
  mounted() {
    security
      .default()
      .authorize()
      .then(() => {
        if (security.default().hasAnyPermissions()) {
          this.$router.replace('home');
          this.$emit('userChanged');
        } else {
          security.default().signOut(false);
          this.$router.replace('access_denied');
        }
      })
      .catch((error) => {
        if (error.message === 'user_cancelled') {
          this.$router.replace('user_cancelled');
        } else if (error.message === 'man_in_the_middle') {
          this.$router.replace('technical_error');
        } else {
          this.$router.replace('access_denied');
        }
      });
  },
};
</script>
<style scoped>
div.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
