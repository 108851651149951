<template>
  <v-dialog v-model="showing" max-width='800px'>
      <v-card>
        <v-card-title class="headline">Edit Metadata</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="metadataItem.tag"
            label='Tag name'
            :disabled="true"
          />
          <v-text-field
            v-model="metadataItem.description"
            label='Description'
          />
          <v-select
            v-model="metadataItem.type"
            :items="types"
            label='Metadata type'
            @change="refreshMetadataItem()"
          ></v-select>

          <div v-if="metadataItem.type === 'categorical'">
            Please add and describe all classes that the metadata item can occupy
            <br/>
            <div v-for="x in metadataItem.options" :key="x.id">
              <v-row class="pa-0">
                <v-col cols=12 md=3>
                  <v-text-field
                    v-model="x.option"
                    label='Class name'
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="x.description"
                    label='Class description'
                  />
                </v-col>
              </v-row>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color='black'
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="addCategory()"
                ><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
              </template>
              <span>Add Category</span>
            </v-tooltip>
          </div>
          <div v-else-if="metadataItem.type === 'boolean'">
            Please give optional descriptions of what true and false mean in this context:
            <div v-for="x in metadataItem.options" :key="x.id">
              <v-row class="pa-0">
                <v-col cols=12 md=3>
                  <v-text-field
                    v-model="x.option"
                    disabled
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="x.description"
                    label='Optional description'
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="close"
          >
            Cancel
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!readyToUpdate()"
            @click="update"
          >
           Update
           <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    metadataOptions: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      metadataItem: {},
      showing: false,
      types: ['boolean', 'categorical', 'numeric'],
    };
  },
  methods: {
    update() {
      const updateFields = {
        tag: this.metadataItem.tag,
        description: this.metadataItem.description,
        type: this.metadataItem.type,
        options: this.metadataItem.options,
      };
      this.$emit('editMetadata', updateFields);
      this.showing = false;
    },
    close() {
      this.showing = false;
      this.metadataItem = JSON.parse(JSON.stringify(this.item));
      this.$emit('cancel');
    },
    addCategory() {
      this.metadataItem.options.push({
        id: this.metadataItem.options.length + 1,
        option: '',
        description: '',
      });
    },
    refreshMetadataItem() {
      const type = this.metadataItem.type;
      this.metadataItem.options = [];
      if (type === 'boolean') {
        this.metadataItem.options.push({
          option: 'true',
          description: '',
        });
        this.metadataItem.options.push({
          option: 'false',
          description: '',
        });
      }
    },
    readyToUpdate() {
      if (JSON.stringify(this.metadataItem) === JSON.stringify(this.$props.item)) { return false; }
      if (this.metadataItem.tag !== this.$props.item.tag) {
        const mco = this.$props.metadataOptions;
        const index = mco.findIndex(o => o.tag === this.metadataItem.tag.toLowerCase());
        if (index !== -1) { return false; }
      }
      if (this.metadataItem.tag === '') { return false; }
      return true;
    },
  },
  watch: {
    show: {
      deep: true,
      handler(show) {
        this.showing = show;
        this.metadataItem = JSON.parse(JSON.stringify(this.$props.item));
      },
    },
  },
};
</script>
<style>

</style>
