var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"white","elevate-on-scroll":""}},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"mr-4 ml-2",attrs:{"alt":"Isazi Logo","contain":"","src":require("./assets/data-curation-logo/data-curation-cake_small.png"),"width":"40"}})],1)]),_c('div',{staticClass:"headline"},[_vm._v("Curated Database")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","horizontal":""}}),(_vm.signedIn())?_c('v-toolbar-items',[_c('v-menu',{attrs:{"offset-y":"","close-on-click":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","color":"white"}},'v-btn',attrs,false),on),[_vm._v(" Explore ")])]}}],null,false,706690619)},[_c('v-list',_vm._l((_vm.exploreMenuItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","close-on-click":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.hasActiveAnouncements ? 'error' : 'normal'}},on),[_c('v-icon',[_vm._v("mdi-bullhorn")])],1)]}}],null,false,23459204)},[_c('v-list',[(_vm.announcements.length === 0)?_c('v-list-item',[_vm._v(" No announcements ")]):_vm._e(),_vm._l((_vm.announcements),function(item,i){return [_c('v-list-item',{key:("announcement." + i)},[_c('v-list-item-icon',[(_vm.announcementSuccessful(item))?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-progress-check ")]):_vm._e(),(_vm.announcementErrored(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-progress-close ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_vm._e(),(_vm.announcementBusy(item))?_c('v-icon',[_vm._v(" mdi-progress-clock ")]):_vm._e()],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.type)+" - "+_vm._s(_vm.representDate(item.creation_date))+" ")]),(item.is_complete)?_c('v-list-item-action',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.confirmAnnouncement(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()],1)]})],2)],1),_c('v-menu',{attrs:{"offset-y":"","close-on-click":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,false,3684225196)},[_c('v-list',[_vm._l((_vm.settingsMenuItems()),function(item,i){return [(item.available)?_c('v-list-item',{key:i,attrs:{"to":item.route}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2)],1),_c('v-menu',{attrs:{"offset-y":"","close-on-click":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"elevation":"0"}},on),[_vm._v(" "+_vm._s(_vm.name())+" "),_c('v-avatar',{attrs:{"color":"primary","size":"36"}},[_c('img',{attrs:{"src":_vm.picture(),"alt":_vm.name()}})])],1)]}}],null,false,3777769844)},[_c('v-list',[_vm._l((_vm.userMenuItems()),function(item,i){return [(item.available)?_c('v-list-item',{key:("user." + i),attrs:{"to":item.route}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2)],1)],1):_vm._e()],1),_c('v-main',{staticStyle:{"background-color":"white"}},[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }