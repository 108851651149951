<template>
    <div>
        <add-scanner
        @addScanner="addScanner"
        :scanners="scanners"
        />
        <v-col cols='12'>
          <v-simple-table
            fixed-header
          >
            <template v-slot:default>
              <thead>
                  <tr>
                      <th width="30%" class="text-left">
                          Name
                      </th>
                      <th width="45%" class="text-left">
                          Description
                      </th>
                      <th width="15%" class="text-left">
                          Action
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr
                  v-for="item in scanners"
                  :key="item.id"
                  >
                    <td>{{ item.scanner_name }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                      <v-btn fab text small color="primary" @click="editScanner(item)">
                        <v-icon small>mdi mdi-file-edit</v-icon>
                      </v-btn>
                      <v-btn fab text small color="red"
                      @click="deleteScanner = item">
                        <v-icon small>mdi mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="scanners.length === 0">
                      No scanners available
                  </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <edit-scanner
          @updateScanner="updateScanner"
          :item="selectedScanner"
          :scanners="scanners"
          :show="showEditScanner"
          @cancel="showEditScanner = false"/>
        <confirm
          label="Delete Scanner"
          :show="deleteScanner !== null"
          @cancel="deleteScanner = null"
          @ok="actionScannerDelete"/>
    </div>
</template>
<script>
import axios from 'axios';
import AddScanner from '../manage_scanners/AddScanner.vue';
import EditScanner from '../manage_scanners/EditScanner.vue';
import Confirm from '../common/Confirm.vue';

export default {
  components: {
    AddScanner,
    EditScanner,
    Confirm,
  },
  data() {
    return {
      scanners: [],
      deleteScanner: null,
      selectedScanner: {},
      showEditScanner: false,
    };
  },
  methods: {
    async addScanner(data) {
      await axios.post('/api/v2/scanner/add', data);
      this.getScanners();
    },
    async getScanners() {
      const response = await axios.get('/api/v2/scanner');
      this.scanners = response.data;
    },
    async actionScannerDelete() {
      const nameObject = { scanner_name: this.deleteScanner.scanner_name };
      await axios.post('/api/v2/scanner/delete', nameObject);
      this.deleteScanner = null;
      this.getScanners();
    },
    async updateScanner(fieldsObject) {
      await axios.post('/api/v2/scanner/update', fieldsObject);
      this.showEditScanner = false;
      this.getScanners();
    },
    editScanner(item) {
      this.selectedScanner = item;
      this.showEditScanner = true;
    },
  },
  mounted() {
    this.getScanners();
  },
};
</script>
<style scoped>
</style>
