<template>
  <v-dialog v-model="showing" max-width="400" persistent>
    <v-card>
      <v-card-title>Edit Metadata</v-card-title>
      <v-card-text>
        <v-row v-for="(field, i) in fields" :key="i" class="nowrap-overflow" >
          <v-text-field
            class="ml-2"
            v-model="field.name"
            outlined
            dense
            :disabled="true"/>
          <v-text-field
            class="ml-2"
            v-model="field.value"
            outlined
            filled
            dense/>
          <v-btn fab text small color="error"
            @click="deleteField(i)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="field"
              label="Name"
              dense/>
          </v-col>
          <v-col cols="5">
            <v-switch
              v-show="newItem.isBinary"
              v-model="value"
              label="Value"
              dense/>
            <v-text-field
              v-show="!newItem.isBinary"
              v-model="value"
              label="Value"
              dense/>
          </v-col>
          <v-col cols="1">
            <v-btn fab text small color="primary"
              @click="changeNewBinary(newItem)">
              <v-icon small>mdi-swap-vertical</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn fab text small color="primary"
              @click="addField()"
              :disabled="!isValid()">
              <v-icon small>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pl-3 red--text" v-show="toBeOverwritten.length > 0">
          This action will override these fields:
          <v-list>
            <v-list-item v-for="(item,i) in toBeOverwritten" :key="i">
              <v-list-item-content>
                <v-list-item-title class="red--text">{{i+1}}. {{item}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="cancel"
          color="error"
        >
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="save"
          color="primary"
          :disabled="disable()"
        >
          OK
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      item: null,
      showing: false,
      newItem: {
        field: null,
        value: null,
        isBinary: false,
      },
      field: '',
      value: '',
      dataset: null,
      fields: [],
      isPreviousField: false,
      toBeOverwritten: [],
    };
  },
  props: {
    datasets: {
      type: Array,
      required: false,
    },
    show: {
      type: Boolean,
      required: false,
    },
    existingFields: {
      type: Array,
      require: false,
    },
  },
  methods: {
    cancel() {
      this.showing = false;
      this.$emit('cancel');
      this.clear();
    },
    changeNewBinary(newItem) {
      this.newItem.isBinary = !newItem.isBinary;
      if (newItem.isBinary) {
        this.newItem.value = false;
      } else {
        this.newItem.value = null;
      }
    },
    isValid() {
      return !(this.field === '' || this.value === '' || this.isPreviousField);
    },
    disable() {
      return (this.field !== '' && this.value === '') || this.fields.length < 1;
    },
    addField() {
      const obj = {};
      obj.name = this.field;
      obj.value = this.value;
      this.fields.push(obj);
      this.value = '';
      this.field = '';
      this.checkFields();
    },
    deleteField(index) {
      this.fields.splice(index, 1);
      this.checkFields();
    },
    save() {
      const obj = {};
      this.fields.forEach((a) => { obj[a.name] = a.value; });
      this.$emit('save', obj);
      this.clear();
    },
    checkFields() {
      const temp = [];
      this.fields.forEach((field) => {
        if (this.$props.existingFields.includes(field.name)) {
          temp.push(field.name);
        }
      });
      this.toBeOverwritten = temp;
    },
    clear() {
      this.fields = [];
      this.value = '';
      this.field = '';
      this.toBeOverwritten = [];
    },
  },
  watch: {
    show: {
      deep: true,
      handler(show) {
        this.showing = show;
        this.dataset = null;
      },
    },
    field: {
      deep: true,
      handler(field) {
        this.isPreviousField = this.fields.map(x => x.name).includes(field);
      },
    },
  },
};
</script>
<style scoped>
.pad {
  margin-bottom: 15px;
}
.nowrap-overflow {
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>
