<template>
    <div>
      <create-subset
        @createSubset="createSubset"
        :projects="projects"
        :metadataOptions="metadataOptions"
        :subsets="subsetNames"
      />
      <v-card class="mt-6">
        <v-simple-table
          fixed-header
        >
          <template v-slot:default>
            <thead>
                <tr>
                  <th width="20%" class="text-left">
                      Name
                  </th>
                  <th width="20%" class="text-left">
                      Project
                  </th>
                  <th width="45%" class="text-left">
                      Description
                  </th>
                  <th width="15%" class="text-left">
                      Action
                  </th>
                </tr>
            </thead>
            <tbody>
              <tr
              v-for="item in subsets"
              :key="item.id"
              >
                <td>{{ item.subset_name }}</td>
                <td>{{ item.project_name }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <v-btn fab text small color="primary" @click="editSubset(item)">
                    <v-icon small>mdi mdi-file-edit</v-icon>
                  </v-btn>
                  <v-btn fab text small color="red"
                  @click="checkLabelling(item)">
                    <v-icon small>mdi mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-if="subsets.length === 0">
                No subsets available
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
        <edit-subset
          @updateSubset="updateSubset"
          :selectedMetadata="selectedMetadata"
          :item="selectedSubset"
          :allMetadataOptions="metadataOptions"
          :subsets="subsetNames"
        />
        <confirm
          label="Delete Subset"
          :show="deleteSubset !== null"
          :isLabelling="isLabelling"
          @cancel="deleteSubset = null"
          @ok="actionSubsetDelete"
        />
    </div>
</template>
<script>
import axios from 'axios';
import CreateSubset from '../manage_subsets/CreateSubset.vue';
import EditSubset from '../manage_subsets/EditSubset.vue';
import Confirm from '../common/Confirm.vue';

export default {
  components: {
    CreateSubset,
    EditSubset,
    Confirm,
  },
  data() {
    return {
      projects: [],
      subsets: [],
      metadataOptions: [],
      selectedMetadata: [],
      selectedSubset: {},
      deleteSubset: null,
      subsetNames: [],
      isLabelling: false,
    };
  },
  methods: {
    async createSubset(data) {
      await axios.post('/api/v2/subset/create', data);
      this.getSubsets();
    },
    async getSubsets() {
      this.subsetNames = [];
      const response = await axios.get('/api/v2/subset');
      this.subsets = response.data;
      this.subsets.forEach(subset => this.subsetNames.push(subset.subset_name));
    },
    async checkLabelling(item) {
      const nameObject = { subset_name: item.subset_name };
      const response = await axios.post('/api/v2/bulk/label/complete', nameObject);
      this.isLabelling = Boolean(Number(response.data));
      this.deleteSubset = item;
    },
    async actionSubsetDelete() {
      const nameObject = { subset_name: this.deleteSubset.subset_name };
      await axios.post('/api/v2/subset/delete', nameObject);
      this.deleteSubset = null;
      this.getSubsets();
    },
    async getMetadata() {
      const response = await axios.get('/api/v2/subset/metadata');
      this.metadataOptions = response.data;
    },
    async getSelectedMetadata(subsetName) {
      const nameObject = { subset_name: subsetName };
      const response = await axios.post('/api/v2/subset/metadata', nameObject);
      this.selectedMetadata = response.data;
    },
    async getProjects() {
      const response = await axios.get('/api/v2/project');
      this.projects = response.data.map(p => p.project_name);
    },
    async updateSubset(fieldsObject) {
      await axios.post('/api/v2/subset/update', fieldsObject);
      this.getSubsets();
    },
    editSubset(item) {
      this.getSelectedMetadata(item.subset_name);
      this.selectedSubset = item;
    },
    refreshData() {
      this.getSubsets();
      this.getMetadata();
      this.getProjects();
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>
<style scoped>
</style>
