<template>
  <div>
    <v-card>
      <v-card-title class="mt-3">API CLIENTS</v-card-title>
      <v-card-text>
      <v-row justify="end" class="mr-5 mt-2 mb-2">
        <v-btn
          right
          color="primary"
          @click="addClient()"
          :disabled="clients.length > 2"
        >
          ADD CLIENT
          <v-icon right="">mdi mdi-plus-thick</v-icon>
        </v-btn>
      </v-row>
      <v-simple-table
        fixed-header>
          <template v-slot:default>
            <thead>
                <tr>
                    <th width="40%" class="text-left">
                      Client ID
                    </th>
                    <th width="20%" class="text-left">
                      Identifier
                    </th>
                    <th width="15%" class="text-left">
                        Active
                    </th>
                    <th width="15%" class="text-left">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in clients"
                :key="item.client_id"
                >
                  <td>{{ item.client_id }}</td>
                  <td>{{ item.identifier }}</td>
                  <td>{{ item.active }}</td>
                  <td>
                    <v-btn
                    fab
                    text
                    small
                    color="red"
                    @click="remove(item.client_id)">
                      <v-icon small>mdi mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="clients.length === 0">
                    No clients available
                </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <response-dialog
    :client="client"
    />
  </div>
</template>
<script>
import axios from 'axios';
import ResponseDialog from './ResponseDialog.vue';

export default {
  components: {
    ResponseDialog,
  },
  data() {
    return {
      clients: [],
      client: {},
    };
  },
  methods: {
    async addClient() {
      const response = await axios.post('/api/v2/client/create/library', {});
      this.client = response.data;
      await this.list();
    },
    async list() {
      const response = await axios.get('/api/v2/client/list');
      this.clients = response.data;
    },
    async remove(clientId) {
      await axios.delete(`/api/v2/client/${clientId}`);
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style lang="scss">
  .v-text-field__details {
    display: none;
  }
</style>
