<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      elevate-on-scroll
    >
      <router-link to="/">
        <div class="d-flex align-center">
          <v-img
            alt="Isazi Logo"
            class="mr-4 ml-2"
            contain
            src="./assets/data-curation-logo/data-curation-cake_small.png"
            width="40"
          />
        </div>
      </router-link>
      <div class="headline">Curated Database</div>

      <v-divider
          class="mx-4"
          inset
          horizontal
      ></v-divider>

      <v-toolbar-items v-if="signedIn()">
        <v-menu offset-y close-on-click transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation='0' color="white"
              v-bind="attrs"
              v-on="on"
            >
              Explore
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in exploreMenuItems"
              :key="index"
              :to='item.route'
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y close-on-click transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :color="hasActiveAnouncements ? 'error' : 'normal'">
              <v-icon>mdi-bullhorn</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="announcements.length === 0">
              No announcements
            </v-list-item>
            <template v-for="(item, i) in announcements">
              <v-list-item :key="`announcement.${i}`">
                <v-list-item-icon>
                  <v-icon v-if="announcementSuccessful(item)" color="green">
                    mdi-progress-check
                  </v-icon>
                  <v-tooltip bottom v-if="announcementErrored(item)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error"
                        v-bind="attrs"
                        v-on="on">
                        mdi-progress-close
                      </v-icon>
                    </template>
                    <span>{{ item.error }}</span>
                  </v-tooltip>
                  <v-icon v-if="announcementBusy(item)">
                    mdi-progress-clock
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ item.type }} - {{ representDate(item.creation_date) }}
                </v-list-item-title>
                <v-list-item-action v-if="item.is_complete">
                  <v-btn text @click="confirmAnnouncement(item)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-menu offset-y close-on-click transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>

          <v-list>
            <template v-for="(item, i) in settingsMenuItems()">
              <v-list-item :key="i" :to='item.route' v-if="item.available">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-menu offset-y close-on-click transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn elevation='0' v-on="on">
              {{ name() }}
              <v-avatar color="primary" size="36">
                <img :src="picture()" :alt="name()">
              </v-avatar>
            </v-btn>
          </template>

          <v-list>
            <template v-for="(item, i) in userMenuItems()">
              <v-list-item :key="`user.${i}`" v-if="item.available" :to='item.route'>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main style='background-color: white'>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
      <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>
<script>
import * as security from '@/services/SecurityService';

export default {
  name: 'App',
  data: () => ({
    announcements: [],
    exploreMenuItems: [
      {
        title: 'Explore Subsets',
        route: '/explore/subsets',
      },
      {
        title: 'Explore Data',
        route: '/explore/data',
      },
    ],
  }),
  computed: {
    hasActiveAnouncements() {
      return this.announcements.some(x => x.is_complete && !x.is_confirmed);
    },
  },
  methods: {
    announcementSuccessful(announcement) {
      return announcement.is_complete && announcement.error === null;
    },
    announcementErrored(announcement) {
      return announcement.is_complete && announcement.error !== null;
    },
    announcementBusy(announcement) {
      return !announcement.is_complete;
    },
    representDate(input) {
      const a = new Date(input).getTime();
      const b = new Date().getTime();
      const c = (b - a) / 1000;
      if (c < 120) {
        return 'moments ago';
      }
      if (c < 7200) {
        return `${Math.floor(c / 60)} minutes ago`;
      }
      return `${Math.floor(c / 3600)} hours ago`;
    },
    picture() {
      const profile = JSON.parse(sessionStorage.getItem('oidc.profile'));
      if (profile.picture === null || profile.picture === undefined) {
        return '/img/user/unknown.png';
      }
      if (profile.picture === 'microsoft://') {
        return '/img/user/microsoft.svg';
      }
      return profile.picture;
    },
    name() {
      const profile = JSON.parse(sessionStorage.getItem('oidc.profile'));
      return `${profile.given_name} ${profile.family_name}`;
    },
    settingsMenuItems() {
      return [
        {
          title: 'Users',
          route: '/settings/users',
          available: security.default().hasPermission('manage'),
        },
        {
          title: 'Manage Projects',
          route: '/settings/projects',
          available: true,
        },
        {
          title: 'Manage Subsets',
          route: '/settings/subsets',
          available: true,
        },
        {
          title: 'Manage Metadata',
          route: '/settings/metadata',
          available: true,
        },
        {
          title: 'Manage Scanners',
          route: '/settings/scanners',
          available: true,
        },
        {
          title: 'API Clients',
          route: '/settings/clients',
          available: true,
        },
      ];
    },
    userMenuItems() {
      const profile = JSON.parse(sessionStorage.getItem('oidc.profile'));
      return [
        {
          title: 'User Details',
          route: `/user/details/${encodeURIComponent(profile.email)}`,
          available: true,
        },
        {
          title: 'Reset Password',
          route: `/user/reset_password/${encodeURIComponent(profile.email)}`,
          available: profile.sub.startsWith('local.'),
        },
        {
          title: 'Sign out',
          route: '/signout',
          available: true,
        },
      ];
    },
    signedIn() {
      return sessionStorage.getItem('oidc.profile') !== null;
    },
    async fetchAnnouncements() {
      const response = await this.$http.get('/api/v2/bulk');
      this.announcements = response.data;
    },
    async confirmAnnouncement(announcement) {
      await this.$http.post('/api/v2/bulk/confirm', { id: announcement.id });
      await this.fetchAnnouncements();
    },
  },
  mounted() {
    this.fetchAnnouncements();
    this.refresh = setInterval(() => {
      this.fetchAnnouncements();
    }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.refresh);
    this.refresh = null;
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Curated Database';
        setTimeout(() => {
          this.$forceUpdate();
        }, 200);
      },
    },
  },
};
</script>
<style scoped>
.v-avatar {
  margin-left: 5px;
}
</style>
