<template>
  <div class="dashboard">
    <v-card min-width="40%">
      <v-card-title>User Details</v-card-title>
      <v-card-subtitle v-show="email"> {{ email }} </v-card-subtitle>
      <v-card-text>
        <v-text-field
          v-model="email"
          :class="{ invalid: !validEmail() }"
          :disabled="!newUser"
          ref="email"
          label="Email"
          hint="e.g. username@example.org"/>
        <v-text-field
          v-model="firstName"
          ref="firstName"
          label="First Name"/>
        <v-text-field
          v-model="lastName"
          label="Last Name"/>
        <v-text-field
          v-model="phone"
          label="Phone"
          hint="e.g. +1 541 754 3010"/>
        <v-text-field
          v-model="picture"
          label="Picture"
          hint="e.g. http://example.org/some/image.png"/>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="error"
            @click="cancel">
            Cancel
            <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="action"
          :class="{disabled: !validEmail()}">
          {{ newUser ? 'Create' : 'Update'}}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios';
import * as security from '@/services/SecurityService';

export default {
  data() {
    return {
      newUser: true,
      email: null,
      firstName: null,
      lastName: null,
      phone: null,
      picture: null,
    };
  },
  methods: {
    validEmail() {
      return this.email !== null && this.email.includes('@');
    },
    async action() {
      try {
        if (this.newUser) {
          await axios.post('/api/v2/user/register', {
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            phone: this.phone,
            picture: this.picture,
          });
          this.$toast.success('User created');
          this.$router.go(-1);
        } else {
          await axios.post('/api/v2/user/update_details', {
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            phone: this.phone,
            picture: this.picture,
          });
          await security.default().refreshTokens(true);
          this.$toast.success('User details updated');
          this.$router.go(-1);
        }
      } catch (ex) {
        this.$toast.error(ex.response.data);
      }
    },
    async cancel() {
      this.$router.go(-1);
    },
  },
  created() {
    this.newUser = this.$route.params.option === 'new';
    if (!this.newUser) {
      const user = security.default().getUser();
      this.email = user.email;
      this.firstName = user.given_name;
      this.lastName = user.family_name;
      this.picture = user.picture;
      this.phone = null;
    }
  },
  mounted() {
    if (this.newUser) {
      this.$refs.email.focus();
    } else {
      this.$refs.firstName.focus();
    }
  },
};
</script>
<style scoped>
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
