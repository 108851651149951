<template>
    <div>
      <create-project
        @addProject="addProject"
        :projects="projects"
      />
      <v-card class="mt-6">
        <v-simple-table
          fixed-header
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th width="20%" class="text-center">
                  Item
                </th>
                <th width="20%" class="text-center">
                  Project Name
                </th>
                <th width="45%" class="text-center">
                  Description
                </th>
                <th width="15%" class="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
                <tr
                 v-for="(item,index) in projects"
                 :key="item.id"
                >
                <td class="text-center">{{ index+1 }}</td>
                <td class="text-center">{{ item.project_name }}</td>
                <td class="text-center">{{ item.description }}</td>
                <td class="text-center">
                  <v-btn fab text small color="primary" @click="editProject(item)">
                    <v-icon small>mdi mdi-file-edit</v-icon>
                  </v-btn>
                  <v-btn fab text small color="red"
                   @click="deleteProject = item">
                    <v-icon small>mdi mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-if="projects.length === 0">
                No projects available
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <div class="d-flex justify-center">
        <v-alert
          type="error"
          v-model="alert"
          border="left"
          close-text="Close Alert"
          dark
          dismissible
        >
        Can not delete a project with datums. First empty the project on the explore screen.
        </v-alert>
      </div>
      <edit-project
        @updateProject="updateProject"
        :item="selectedProject"
      />
      <confirm
        label="Delete Project"
        :show="deleteProject!== null"
        @cancel="deleteProject = null"
        @ok="actionProjectDelete"
      />
    </div>
</template>
<script>
import axios from 'axios';
import CreateProject from '../manage_projects/CreateProject.vue';
import EditProject from '../manage_projects/EditProject.vue';
import Confirm from '../common/Confirm.vue';

export default {
  components: {
    CreateProject,
    EditProject,
    Confirm,
  },
  data() {
    return {
      project: '',
      projects: [],
      selectedProject: {},
      deleteProject: null,
      alert: false,
    };
  },
  methods: {
    async addProject(item) {
      await axios.post('/api/v2/project/add', item);
      this.getProjects();
      this.project = '';
    },
    async actionProjectDelete() {
      this.alert = false;
      try {
        const nameObject = { project_name: this.deleteProject.project_name };
        await axios.post('/api/v2/project/delete', nameObject);
        this.deleteProject = null;
        this.getProjects();
      } catch (ex) {
        this.alert = true;
        this.deleteProject = null;
      }
    },
    async getProjects() {
      const response = await axios.get('/api/v2/project');
      this.projects = response.data;
    },
    async updateProject(fieldsObject) {
      await axios.post('/api/v2/project/update', fieldsObject);
      this.getProjects();
    },
    editProject(item) {
      this.selectedProject = item;
    },
    refreshData() {
      this.getProjects();
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>
<style scoped>
</style>
