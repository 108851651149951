<template>
  <v-dialog
    v-model="show"
    max-width="400"
    persistent>
    <v-card v-if="item !== null">
      <v-card-title>Edit Metadata</v-card-title>
      <v-card-text>
        <div class="pad">
          <img
            v-if="
            item.files &&
            item.files[file] &&
            item.files[file][order]"
            :src="`/api/v2/file/${item.files[file][order]}`"
            :alt="item.files[file]"
            style="max-height: 25px;"/>
          <v-img
            v-else
            src="../../assets/placeholder.png"
            max-width="150"
            max-height="25"
          />
        </div>
        <template v-for="field in fields()" >
          <v-row :key="`metadata.${field}`">
            <v-col cols="9">
              <v-switch v-if="item.isBinary[field]"
                v-model="item.metadata[field]"
                :label="`${field}: ${item.metadata[field]}`"
                @change="item.isDirty[field] = true"
                @keyup="item.isDirty[field] = true"
                dense/>
              <v-text-field v-if="!item.isBinary[field]"
                v-model="item.metadata[field]"
                :label="field"
                @change="item.isDirty[field] = true"
                @keyup="item.isDirty[field] = true"
                dense/>
            </v-col>
            <v-col cols="1">
              <v-btn fab text small color="primary"
                @click="changeBinary(item, field)">
                <v-icon small>mdi-swap-vertical</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn fab text small color="error"
                @click="deleteField(field)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="newItem.field"
              label="Name"
              dense/>
          </v-col>
          <v-col cols="5">
            <v-switch
              v-show="newItem.isBinary"
              v-model="newItem.value"
              label="Value"
              dense/>
            <v-text-field
              v-show="!newItem.isBinary"
              v-model="newItem.value"
              label="Value"
              dense/>
          </v-col>
          <v-col cols="1">
            <v-btn fab text small color="primary"
              @click="changeNewBinary(newItem)">
              <v-icon small>mdi-swap-vertical</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn fab text small color="primary"
              @click="addField"
              :disabled="!isValid">
              <v-icon small>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="cancel"
          color="error"
        >
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="save"
          color="primary"
          :disabled="!isDirty()"
        >
          Save
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      item: null,
      newItem: {
        field: null,
        value: null,
        isBinary: false,
      },
      show: false,
    };
  },
  props: {
    value: {
      type: Object,
      required: false,
    },
    metadata: {
      type: Array,
      required: false,
    },
    file: {
      type: String,
      required: false,
    },
    order: {
      type: Number,
      required: false,
    },
  },
  computed: {
    isValid() {
      let hasField = false;
      /* eslint-disable */
      for (const field in this.item.metadata) {
        if (field === this.newItem.field && !this.item.isDeleted[field]) {
          hasField = true;
          break
        }
      }
      return this.newItem.field !== null
        && this.newItem.field.trim() !== ''
        && !hasField;
    },
  },
  methods: {
    fields() {
      const fields = [];
      /* eslint-disable */
      for (const field in this.item.metadata) {
        if ((this.metadata.includes(field) || this.item.isNew[field]) && !this.item.isDeleted[field]) {
          fields.push(field)
        }
      }
      return fields
    },
    isDirty() {
      /* eslint-disable */
      for (const key in this.item.isDirty) {
        if (this.item.isDirty[key] === true) {
          return true;
        }
      }
      return false;
    },
    changeNewBinary(newItem) {
      newItem.isBinary = !newItem.isBinary
      if (newItem.isBinary) {
        newItem.value = false
      } else {
        newItem.value = null
      }
    },
    changeBinary(datum, field) {
      datum.isBinary[field] = !datum.isBinary[field]
      if (datum.isBinary[field]) {
        datum.metadata[field] = false
      } else {
        datum.metadata[field] = null
      }
      this.$forceUpdate()
    },
    save() {    
      let item = JSON.parse(JSON.stringify(this.item));

      const changes = {};
      const deletedFields = [];
      let hasNewFields = false;

      /* eslint-disable */
      for (const field in item.metadata) {
        if (item.isDirty[field] && !item.isDeleted[field]) {
          changes[field] = this.item.metadata[field]
        }
        if (item.isDeleted[field] && !item.isNew[field]) {
          deletedFields.push(field);
        }
        if (item.isNew[field] && !item.isDeleted[field]) {
          hasNewFields = true;
        }
      }

      delete item.isDirty
      delete item.isNew
      delete item.isDeleted
      delete item.isBinary
      item['metadata'] = changes

      this.$emit('save', item, deletedFields, hasNewFields);
      this.item = null;
      this.show = false;
    },
    cancel() {
      this.$emit('cancel');
      this.item = null;
      this.show = false;
    },
    addField() {
      let field = this.newItem.field;
      let value = this.newItem.value;

      this.item.metadata[field] = value;
      this.item.isDirty[field] = true;
      this.item.isNew[field] = true;
      this.item.isDeleted[field] = false;
      this.item.isBinary[field] = (value !== null && typeof(value) === 'boolean')

      this.newItem = {
        field: null,
        value: null,
        isBinary: false,
      };
      this.$forceUpdate()
    },
    deleteField(field) {
      this.item.isDeleted[field] = true
      this.item.isDirty[field] = true
      this.$forceUpdate()
    },
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        if (value === null) {
          this.item = null;
          this.show = false;
          return;
        }

        // clone the item
        let item = JSON.parse(JSON.stringify(value));

        // add dirty flags, types, deleted and new
        /* eslint-disable */
        item.isDirty = {}
        item.isBinary = {}
        item.isNew = {}
        item.isDeleted = {}
        for (const field in item.metadata) {
          item.isDirty[field] = false
          item.isBinary[field] = false
          item.isDeleted[field] = false
          if (item.metadata[field] !== null &&
              typeof(item.metadata[field]) === 'boolean') {
            item.isBinary[field] = true
          }
        }

        this.item = item;
        this.show = true;
      },
    },
  }
};
</script>
<style scoped>
.pad {
  margin-bottom: 15px;
}
</style>
