<template>
    <v-menu open-on-click bottom offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          color="secondary darken-1"
          v-on="on"
          outlined
        >
          {{title}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>{{title}}</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              Mime-Type:
              <div class='d-inline pl-2 grey--text'>{{history_datum.mime_type}}</div>
            </v-list-item>
            <v-list-item>
              Project ID:
              <div class='d-inline pl-2 grey--text'>{{history_datum.project_id}}</div>
            </v-list-item>
            <v-list-item>
              Label:
              <div v-for='(value, key, index) in history_datum.label' v-bind:key='index'>
                <v-list-item>
                  <div class='pr-2 grey--text'>{{ key }}:</div>
                  <div class='d-inline'>{{ value }}</div>
                </v-list-item>
              </div>
            </v-list-item>
            <v-list-item>
              Metadata:
              <v-list v-for='(value, key, index) in history_datum.metadata' v-bind:key='index'>
                <v-list-item>
                  <div class='pr-2 grey--text'>{{ key }}:</div>
                  <div >{{ value }}</div>
                </v-list-item>
              </v-list>
            </v-list-item>
            <v-list-item>Date Created:
              <div class='d-inline pl-2 grey--text'>{{history_datum.date_added}}</div>
            </v-list-item>
            <v-list-item>
              Date Modified:
              <div class='d-inline pl-2 grey--text'>{{history_datum.date_modified}}</div>
            </v-list-item>
            <v-list-item>
              Generated:
              <div class='d-inline pl-2 grey--text'>{{history_datum.generated_data}}</div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

    </v-menu>
</template>

<script>
export default {
  name: 'DataItemComponent',
  props: [
    'history_datum',
    'title',
  ],
  data() {
    return {
      datum: {
        datum: 0,
        mime_type: '',
        project_name: '',
        label: [],
        metadata: [],
        date_created: '',
        date_modified: '',
        generated: true,
      },
    };
  },
  methods: {

  },
};
</script>
