<template>
  <v-dialog v-model="showing" max-width="400" persistent>
    <v-card>
      <v-card-title>Bulk Remove Metadata</v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-autocomplete
            v-model="values"
            :items="availableMetadata"
            filled
            dense
            chips
            small-chips
            deletable-chips
            label="tags to remove"
            multiple
          ></v-autocomplete>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="cancel"
          color="error"
        >
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="save"
          color="primary"
          :disabled="values.length === 0"
        >
          OK
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      showing: false,
      values: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    availableMetadata: {
      type: Array,
      required: false,
    },
  },
  methods: {
    cancel() {
      this.showing = false;
      this.$emit('cancel');
    },
    save() {
      this.showing = false;
      this.$emit('save', this.values);
    },
    disable() {
      return true;
    },
  },
  watch: {
    show: {
      deep: true,
      handler(show) {
        this.showing = show;
        this.values = [];
      },
    },
  },
};
</script>
<style scoped>
</style>
