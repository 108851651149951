<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title>Bulk Action: Scan</v-card-title>
      <v-card-text>
          <v-text-field
            label="Target Field"
            v-model="targetField"
            hint="Where to store the results"/>
          <v-autocomplete
            label="Target Scanner"
            dense
            filled
            v-model="targetScanner"
            :items="scanners"
            hint="Scanner to send to"/>
          <v-text-field
            v-show="targetField"
            class="ml-2"
            label="Confidence Field"
            v-model="confidenceField"
            filled
            dense
            hint="Where to store confidence values"
            :disabled="true"/>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="cancel"
          color="error"
        >
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="confirm"
          color="primary"
          :disabled="!isValid"
        >
          OK
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      targetField: null,
      targetScanner: '',
      showing: false,
    };
  },
  props: {
    scanners: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    isValid() {
      return this.targetField !== null && this.targetField !== '';
    },
    confidenceField() {
      return `${this.targetField}_confidence`;
    },
  },
  methods: {
    confirm() {
      this.showing = false;
      const item = {
        target_scanner: this.targetScanner,
      };
      this.$emit('ok', this.targetField, item);
    },
    cancel() {
      this.showing = false;
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      deep: true,
      handler(show) {
        this.showing = show;
      },
    },
  },
};
</script>
<style scoped>
.pad {
  margin-bottom: 15px;
}
div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: left;
}
div.v-card__text div {
  margin: 5px;
}
</style>
