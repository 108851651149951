<template>
  <v-container>
    <v-row justify='center' class="pb-8" style="margin-top:10vh">
      <h1 :class="[$vuetify.breakpoint.lgAndUp ? 'display-4' : 'display-2']">
        Isazi's Curated Database
      </h1>
    </v-row>
    <v-row
      class="ma-6"
      justify="center"
      no-gutters
    >
      <v-col cols=12 md=4 class="px-8">
        <v-img
          v-if="moveSliceOut === false"
          alt="Isazi Logo"
          contain
          src="../assets/data-curation-logo/data-curation-cake_in.png"
          :max-height=imageHeight
        />
        <v-img
          v-if="moveSliceOut === true"
          alt="Isazi Logo"
          contain
          src="../assets/data-curation-logo/data-curation-cake_out.png"
          :max-height=imageHeight
        />
      </v-col>
      <v-col cols=12 md=4 xl=3 align-self='center'>
        <v-card
          flat outlined color='isazired lighten-1'
          class="headline"
          to="/explore/data"
        >
          <v-card-title>
            {{exploreTitle}}
            <v-spacer></v-spacer>
            <v-icon color='black'>mdi-arrow-right</v-icon>
          </v-card-title>
        </v-card>
        <v-card
          flat outlined color='isaziblue lighten-1'
          class="headline"
          @mouseenter="moveSliceOut = true"
          @mouseleave="moveSliceOut = false"
          to="/explore/subsets"
        >
          <v-card-title>
            {{exploreSubsetTitle}}
            <v-spacer></v-spacer>
            <v-icon color='black'>mdi-arrow-right</v-icon>
          </v-card-title>
        </v-card>
        <v-card
          flat outlined color='isaziyellow'
          class="headline"
          to="/settings/subsets"
        >
          <v-card-title>
            {{manageSubsetTitle}}
            <v-spacer></v-spacer>
            <v-icon color='black'>mdi-arrow-right</v-icon>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      moveSliceOut: false,
    };
  },
  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100px';
        case 'sm': return '220px';
        case 'md': return '320px';
        case 'lg': return '320px';
        case 'xl': return '420px';
        default: return '320px';
      }
    },
    exploreTitle() {
      return 'Explore Data';
    },
    exploreSubsetTitle() {
      return 'Explore Subsets';
    },
    manageSubsetTitle() {
      return 'Manage Subsets';
    },
  },

};
</script>

<style>
.explore_btn {
  width: 200px;
}
</style>
