<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row justify="end" class="mr-5 mt-2">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            add scanner
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="headline">
          Add A New Scanner
        </v-card-title>
            <v-card-text>
              <v-layout wrap align-center>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                      v-model="scannerName"
                      outlined
                      label="Scanner Name">
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                    v-model="description"
                    outlined
                    label="Scanner Description">
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="dialog = false"
          >
            Cancel
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disable()"
            @click="close"
          >
           Add
           <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    scanners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      scannerName: '',
      description: '',
    };
  },
  methods: {
    close() {
      const scannerFields = {
        scanner_name: this.scannerName,
        description: this.description,
      };
      this.$emit('addScanner', scannerFields);
      this.dialog = false;
      this.clear();
    },
    clear() {
      this.scannerName = '';
      this.description = '';
    },
    disable() {
      if (this.$props.scanners.find(o => o.scanner_name === this.scannerName)) { return true; }
      if (this.scannerName === '') { return true; }
      return false;
    },
  },
};
</script>
