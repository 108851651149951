<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700"
    >
      <v-card v-if="scanner !== null">
        <v-card-title class="headline">
          Edit Scanner
        </v-card-title>
            <v-card-text>
              <v-layout wrap align-center>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                    v-model="scanner.scanner_name"
                    outlined
                    label="Scanner Name"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                      v-model="scanner.description"
                      outlined
                      label="Scanner Description">
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="cancel"
          >
            Cancel
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disable()"
            @click="updateScanner"
          >
           Update
           <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    scanners: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      scanner: null,
      oldName: '',
    };
  },
  methods: {
    updateScanner() {
      const updateFields = {
        old_scanner_name: this.oldName,
        scanner_name: this.scanner.scanner_name,
        description: this.scanner.description,
      };
      this.$emit('updateScanner', updateFields);
      this.dialog = false;
    },
    cancel() {
      this.$emit('cancel');
      this.scanner = JSON.parse(JSON.stringify(this.item));
    },
    disable() {
      if (JSON.stringify(this.scanner) === JSON.stringify(this.$props.item)) { return true; }
      if (this.scanner.scanner_name !== this.$props.item.scanner_name) {
        if (this.$props.scanners.find(o => o.scanner_name === this.scanner.scanner_name)) {
          return true;
        }
      }
      if (this.scanner.scanner_name === '') { return true; }
      return false;
    },
  },
  watch: {
    item() {
      this.scanner = JSON.parse(JSON.stringify(this.item));
      this.oldName = this.$props.item.scanner_name;
      this.dialog = true;
    },
    show() {
      this.dialog = this.$props.show;
    },
  },
};
</script>
