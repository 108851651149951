<template>
  <v-dialog v-model="showing" max-width="400">
    <v-card>
      <v-card-title>Bulk Actions: Assign</v-card-title>
      <v-card-text>
        <v-autocomplete
          label="Subset"
          dense
          filled
          v-model="dataset"
          :items="datasets"
          hint="Subset to assign data to"/>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="cancel"
          color="error"
        >
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="confirm"
          color="primary"
          :disabled="dataset === null"
        >
          OK
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      showing: false,
      dataset: null,
    };
  },
  props: {
    datasets: {
      type: Array,
      required: false,
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    confirm() {
      this.showing = false;
      this.$emit('ok', this.dataset);
    },
    cancel() {
      this.showing = false;
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      deep: true,
      handler(show) {
        this.showing = show;
        this.dataset = null;
      },
    },
  },
};
</script>
<style scoped>
.pad {
  margin-bottom: 15px;
}
div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: left;
}
div.v-card__text div {
  margin: 5px;
}
</style>
