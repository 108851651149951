<template>
    <div>
        <v-card>
            <v-card-title>Explore Subsets</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete
                        dense
                        filled
                        v-model="subset"
                        :items="subsetNames"
                        label="Subset"/>
                    </v-col>
                    <v-col cols="6" class="container">
                        <v-autocomplete
                            clearable
                            dense
                            filled
                            :items="fields"
                            label="Field Assistant"
                            @change="applyField"
                            @clear="clearExpression"/>
                        <v-btn fab text small color="primary"
                            @click="getFields">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-textarea
                    rows="2"
                    dense
                    clearable
                    counter
                    background-color="#eeeeff"
                    v-model="expression"
                    label="Query"/>
            </v-card-text>
            <v-card-actions>
              <v-row align="center">
                <v-col cols="3">
                <v-btn color="primary"
                    @click="search">
                    Search
                    <v-icon>mdi-file-find</v-icon>
                </v-btn>
                <v-menu open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                        >
                            Bulk Actions
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list :disabled="data.length === 0">
                        <v-list-item link @click="confirmBulkUpdate = true">
                            <v-list-item-icon>
                                <v-icon>mdi-book-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Update</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="confirmBulkLabel = true">
                            <v-list-item-icon>
                                <v-icon>mdi-file-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Label</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="confirmBulkUnassign = true">
                            <v-list-item-icon>
                                <v-icon>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Unassign</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="showRemoveMetadata = true">
                          <v-list-item-icon>
                            <v-icon>mdi-tag-remove</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Remove Metadata</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="confirmBulkScan = true">
                          <v-list-item-icon>
                            <v-icon>mdi-data-matrix-scan</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Scan</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="confirmBulkExport = true">
                          <v-list-item-icon>
                            <v-icon>mdi-file-export</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export Subset</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                <v-text-field
                  label="Datum Limit"
                  v-model="datumLimit"
                  hide-details
                  type="number"
                />
                </v-col>
                <v-col cols="auto">
                  <div></div>
                </v-col>
              </v-row>
            </v-card-actions>
        </v-card>
        <v-card>
            <v-card-text>
               <v-row>
                <v-col cols="2">
                   <v-autocomplete
                        clearable
                        dense
                        filled
                        :items="files"
                        v-model="selectedFile"
                        label="File Type"/>
                </v-col>
                <v-col cols="10" class="container">
                    <v-autocomplete
                        dense
                        filled
                        multiple
                        :items="availableHeaders"
                        v-model="selectedHeaders"
                        label="Headers"/>
                    <v-btn fab text small color="primary"
                        @click="getFields">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-col>
               </v-row>
               <v-data-table :headers="headers" :items="data"
                    item-key="curated_datum_id"
                    :server-items-length="dataLength"
                    :footer-props="{'items-per-page-options':[50, 100]}"
                    :options.sync="options"
                    :loading="loading"
                    loading-text="Loading... Please wait">
                      <!-- eslint-disable max-len -->
                    <template v-if="chosenSubsetProject === 'Brandi'" v-slot:item.files="{ item }">
                      <img
                        v-if="item.files && item.files[selectedFile] && item.files[selectedFile][selectedOrder]"
                        :src="`/api/v2/thumbnail/${item.files[selectedFile][selectedOrder]}`"
                        :alt="item.files[selectedFile][selectedOrder]"
                        :onerror="`this.onerror=null;this.src='/api/v2/file/${item.files[selectedFile][selectedOrder]}';`"
                        style="cursor: pointer; max-height: 25px;"
                        @click="view(item.files[selectedFile][selectedOrder])"/>
                      <v-img
                        v-else
                        src="../../assets/placeholder.png"
                        max-width="150"
                        max-height="25"
                      >
                        <template v-slot>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey"
                              :size="25"
                              :width="4"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </template>
                    <template v-else v-slot:item.files="{ item }">
                      <img
                        v-if="item.files && item.files[selectedFile] && item.files[selectedFile][selectedOrder]"
                        :src="`/api/v2/file/${item.files[selectedFile][selectedOrder]}`"
                        :alt="item.files[selectedFile][selectedOrder]"
                        style="max-height: 25px;"/>
                      <v-img
                        v-else
                        src="../../assets/placeholder.png"
                        max-width="150"
                        max-height="25"
                      >
                        <template v-slot>
                          <v-row
                            class="ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey"
                              :size="25"
                              :width="4"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn fab text small color="primary"
                        @click="selectedItem = item">
                        <v-icon small>mdi-file-edit</v-icon>
                        </v-btn>
                        <v-btn fab text small color="error"
                        @click="deleteDatum = item">
                        <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <edit-datum
          :value="selectedItem"
          :metadata="selectedMetadata"
          :file="selectedFile"
          :order="selectedOrder"
          @save="saveItem"
          @cancel="selectedItem = null"/>
        <confirm
          label="Bulk Action: Export Subset"
          :show="confirmBulkExport"
          @cancel="confirmBulkExport = false"
          @ok="actionBulkExport"
          :code="false"/>
        <confirm
          label="Bulk Action: Remove"
          :show="confirmBulkUnassign"
          @cancel="confirmBulkUnassign = false"
          @ok="actionBulkRemove"
          :code="true"/>
        <confirm
          label="Delete Datum"
          :show="deleteDatum !== null"
          @cancel="deleteDatum = null"
          @ok="actionDatumDelete"/>
        <confirm-label
          :show="confirmBulkLabel"
          @cancel="confirmBulkLabel = false"
          @ok="actionBulkLabel"
          :fields="fields"
          :datasets="datasets"
          :fileType="selectedFile"
          :fileTypes="files"/>
        <confirm-update
          :show="confirmBulkUpdate"
          :existingFields="fields"
          @cancel="confirmBulkUpdate = false"
          @save="actionBulkUpdate"/>
        <confirm-scan
          :show="confirmBulkScan"
          :scanners="scanners"
          @cancel="confirmBulkScan = false"
          @ok="actionBulkScan"/>
        <remove-metadata
          :show="showRemoveMetadata"
          @cancel="showRemoveMetadata = false"
          @save="codeBulkRemoveMetadata"
          :availableMetadata="availableRemovableHeaders"
          />
        <confirm
          label="Bulk Action: Metadata Removal"
          :show="confirmBulkMetadataRemoval"
          @cancel="cancelBulkRemoveMetadata"
          @ok="actionBulkRemoveMetadata"
          :code="true"/>
    </div>
</template>
<script>
import ConfirmScan from './ConfirmScan.vue';
import Confirm from '../common/Confirm.vue';
import ConfirmLabel from './ConfirmLabel.vue';
import EditDatum from './EditDatum.vue';
import ConfirmUpdate from './ConfirmUpdate.vue';
import RemoveMetadata from './RemoveMetadata.vue';

export default {
  components: {
    Confirm,
    ConfirmLabel,
    EditDatum,
    ConfirmUpdate,
    RemoveMetadata,
    ConfirmScan,
  },
  data() {
    return {
      projects: [],
      datasets: [],
      subset: '',
      subsetNames: [],
      subsets: [],
      expression: '',
      fields: [],
      data: [],
      files: ['filled_image', 'template_image', 'labeller', 'labeller_segment', 'labeller_template', 'labeller_segment_template'],
      orders: [0, 1],
      selectedHeaders: ['files', 'encoded.field_type', 'encoded.label', 'encoded.date_added', 'encoded.date_modified'],
      selectedFile: 'filled_image',
      selectedOrder: 0,
      selectedItem: null,
      options: {},
      pagination: { rowsPerPage: 50 },
      deleteDatum: null,
      loading: false,
      confirmBulkUpdate: false,
      confirmBulkUnassign: false,
      confirmBulkLabel: false,
      confirmBulkScan: false,
      confirmBulkExport: false,
      scanners: [],
      removeMetadataList: [],
      confirmBulkMetadataRemoval: false,
      showRemoveMetadata: false,
      chosenSubsetProject: '',
      datumLimit: 1000000,

      archived: 0,
      refreshInterval: null,
    };
  },
  computed: {
    dataLength() {
      if (this.data.length === 0) {
        return 0;
      }
      return this.data[0].total;
    },
    availableRemovableHeaders() {
      const headers = ['files', 'field_type', 'label', 'date_added', 'date_modified', 'last_modifier'];
      return this.fields.filter(x => !headers.includes(x));
    },
    availableHeaders() {
      const fh = [{ text: 'files', value: 'files', sortable: true }];
      const fields = {
        field_type: 'field_type', label: 'label', added: 'date_added', modified: 'date_modified', is_generated: 'is_generated',
      };
      Object.entries(fields).forEach((x) => {
        if (!this.fields.includes(x[1])) {
          fh.push({ text: x[0], value: `encoded.${this.encode(x[1])}`, sortable: false });
        }
      });
      return this.fields.map(x => ({ text: x, value: `encoded.${this.encode(x)}` })).concat(fh);
    },
    headers() {
      return this.selectedHeaders
        .map(s => ({ text: this.decode(s.replace('encoded.', '')), value: s, sortable: true }))
        .concat([{
          text: 'actions', value: 'actions', sortable: false, width: '125px',
        }]);
    },
    selectedMetadata() {
      return this.selectedHeaders
        .filter(s => s.startsWith('encoded.') && !(s === 'encoded.date_added' || s === 'encoded.date_modified'))
        .map(s => this.decode(s.replace('encoded.', '')));
    },
    project() {
      if (this.subset === '') { return ''; }
      return this.subsets.find(s => s.subset_name === this.subset).project_name;
    },
  },
  methods: {
    loadArchivedImages() {
      if (this.archived > 0 && this.refreshInterval === null) {
        this.refreshInterval = setInterval(() => {
          this.getData();
        }, 30000);
        return;
      }
      if (this.archived === 0 && this.refreshInterval !== null) {
        clearInterval(this.refreshInterval);
      }
    },
    view(img) {
      const imgsrc = `/api/v2/file/${img}`;
      window.open(imgsrc, 'viewwin', 'width=600,height=600');
    },
    encode(str) {
      if (str === null || str === undefined) {
        return null;
      }
      return str.replace('.', '__2E__');
    },
    decode(str) {
      if (str === null || str === undefined) {
        return null;
      }
      return str.replace('__2E__', '.');
    },
    clearExpression() {
      this.expression = '';
    },
    applyField(event) {
      if (event === undefined) {
        return;
      }
      const exp = this.expression === null ? '' : this.expression;
      this.expression = exp + ((exp.length === 0 || exp[exp.length - 1] === ' ') ? '' : ' ') + event;
    },
    cancelBulkRemoveMetadata() {
      this.confirmBulkMetadataRemoval = false;
      this.removeMetadataList = [];
    },
    codeBulkRemoveMetadata(metadataList) {
      this.showRemoveMetadata = false;
      this.confirmBulkMetadataRemoval = true;
      this.removeMetadataList = metadataList;
    },
    async actionBulkRemoveMetadata() {
      this.confirmBulkMetadataRemoval = false;
      await this.$http.post('/api/v2/bulk/remove_metadata', {
        project: this.project,
        dataset: this.subset,
        query: this.expression,
        metadata: { remove: this.removeMetadataList },
      });
      this.$toast.success('Bulk remove metadata queued');
      this.removeMetadataList = [];
      this.getFields();
    },
    async search() {
      await this.getData();
      this.options.page = 1;
    },
    async getProjects() {
      const response = await this.$http.get('/api/v2/project');
      this.projects = response.data;
    },
    async getSubsets() {
      const response = await this.$http.get('/api/v2/subset');
      this.subsets = response.data;
      this.subsets.forEach(subset => this.subsetNames.push(subset.subset_name));
    },
    async getFields() { // get all metadata for a subset
      if (this.subset !== null) {
        const response = await this.$http.post('/api/v2/data/fields', { subset: this.subset });
        this.fields = response.data;
      }
    },
    async getScanners() {
      const response = await this.$http.get('/api/v2/scanner');
      this.scanners = response.data.map(o => o.scanner_name);
    },
    async getLabellingDatasets() {
      const response = await this.$http.get('/api/v2/bulk/labelling_datasets');
      this.datasets = response.data;
    },
    async getData() {
      try {
        let archived = 0;
        this.data = [];
        this.loading = true;
        const sortBy = this.options.sortBy;
        const sortDesc = this.options.sortDesc;
        const page = this.options.page;
        const itemsPerPage = this.options.itemsPerPage;
        const response = await this.$http.post('/api/v2/data/query', {
          project: this.project,
          subset: this.subset,
          expression: this.expression,
          offset: itemsPerPage * (page - 1),
          limit: itemsPerPage,
          datum_limit: parseInt(this.datumLimit, 10),
          sort: sortBy.length > 0 ? this.decode(sortBy[0]).replace('encoded.', 'metadata.') : null,
          asc: sortDesc.length > 0 ? sortDesc[0] : null,
        });
        /* eslint-disable */
        for (const datum of response.data) {
          const encoded = {};
          if(datum.status === 'archived'){
            archived = archived + 1
          }
          /* eslint-disable */
          for (const key in datum.metadata) {
            if (key === 'date_added' || key === 'date_modified') {
              encoded[this.encode(key)] = new Date(datum.metadata[key]).toLocaleDateString('en-ZA');
              continue;
            }
            encoded[this.encode(key)] = datum.metadata[key];
          }
          datum.encoded = encoded;
        }
        this.data = response.data;
        this.loadArchivedImages()
      } finally {
        this.loading = false
      }
    },
    async saveItem(item, deletedFields, hasNewFields) {
      await this.$http.post('/api/v2/data/metadata', {
        project: this.project,
        curated_datum_id: item.curated_datum_id,
        metadata: item.metadata,
        deleted_fields: deletedFields,
        has_new_fields: hasNewFields
      })
      this.$toast.success('Datum saved');
      this.selectedItem = null
      this.getData();
    },
    async actionBulkUpdate(fields) {
     this.confirmBulkUpdate = false
      await this.$http.post('/api/v2/bulk/update', { 
        project: this.project,
        dataset: this.subset,
        metadata: fields,
        query: this.expression,
      })
      this.$toast.success('Bulk update queued');
      this.getFields();
    },
    async actionBulkRemove() {
     this.confirmBulkUnassign = false
      await this.$http.post('/api/v2/bulk/unassign', { 
        project: this.project,
        dataset: this.subset,
        query: this.expression,
      })
      this.$toast.success('Bulk unassign queued');
    },
    async actionBulkLabel(targetField, item) {
      await this.$http.post('/api/v2/bulk/label', { 
        project: this.project,
        dataset: this.subset,
        query: this.expression,
        target_field: targetField,
        metadata: item,
      })
      this.confirmBulkLabel = false
      this.$toast.success('Bulk label queued');
    },
    async actionBulkScan(targetField, item) {
      await this.$http.post('/api/v2/bulk/scan', { 
        project: this.project,
        dataset: this.subset,
        query: this.expression,
        target_field: targetField,
        metadata: item,
      })
      this.confirmBulkScan = false
      this.$toast.success('Bulk scan queued');
    },
    async actionDatumDelete() {
      await this.$http.post('/api/v2/subset/datum/delete', { 
        subset_name : this.subset,
        curated_datum_id: this.deleteDatum.curated_datum_id,
      })
      this.deleteDatum = null
      this.$toast.success('Datum deleted from subset');
      this.getData();
    },
    async actionBulkExport() {
     this.confirmBulkExport = false
      await this.$http.post('/api/v2/bulk/export', { 
        project: this.project,
        dataset: this.subset,
        query: this.expression,
      })
      this.$toast.success('Subset queued for export');
    }
  },
  mounted (){
    this.getSubsets();
    this.getProjects();
    // this.subset = sessionStorage.getItem('chosen.subset')
    this.getScanners();
    this.getLabellingDatasets();
  },
  watch: {
    options: {
      handler () {
        this.getData()
      },
      deep: true,
    },
    subset: {
      handler () {
        this.getFields();
        this.getData();
        let temp = this.subsets.find(o => o.subset_name === this.subset);
        if ( temp ){ 
          this.selectedFile = temp.file_type; 
        } 
        this.chosenSubsetProject = this.subsets.filter(o => o.subset_name === this.subset).map(o => o.project_name)[0];
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // sessionStorage.setItem('chosen.subset', this.subset)
  }
};
</script>
<style scoped>
button.v-btn {
  margin-left: 1px;
  margin-right: 1px;
}
div.container {
  display: flex;
  justify-items: center;
}
</style>
