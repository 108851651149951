<template>
  <v-container v-if="ready">
    <v-row>
      <v-col cols="12" md="4" offset-md="4">
        <v-card  flat outlined
          v-if="localAuthenticationSupported()">
          <v-card-title class="headline">
            Sign in
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="clientId"
              ref="clientId"
              label="Email"
              :error="!validClientId()"/>
            <v-text-field
              type="password"
              v-model="clientSecret"
              label="Password"
              :error="!validClientSecret()"
              @keyup.enter="!connected || !validClientId() ||
                !validClientSecret() ? null : signInLocal()"/>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="!connected || !validClientId() ||
                !validClientSecret() ? null : signInLocal()">
              Sign in
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="googleAuthenticationSupported() || microsoftAuthenticationSupported()"
          flat outlined>
          <v-card-title class="headline"
            v-if="!localAuthenticationSupported()">
            Sign in
          </v-card-title>
          <v-card-text>
            <div id="button-div" style="text-align: center;">
              <div style="width: 230px; padding-top: 24px; display: inline-block;">
                <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
                <!-- Google Sign in Button -->
                <div
                  v-if="googleAuthenticationSupported()"
                  @click="signInGoogle"
                  style="background-color: #4285f4; cursor: pointer; margin-bottom: 16px;"
                >
                  <img src="/img/sign-in/google.svg" style="float: left;" />
                  <div
                    style="color: white; line-height: 46px; font-family: 'Roboto'; font-size: 14pt;"
                  >
                    &nbsp;Sign in with Google
                  </div>
                </div>

                <!-- Microsoft sign in -->
                <div
                  v-if="microsoftAuthenticationSupported()"
                  @click="signInMicrosoft"
                  style="cursor: pointer; margin-bottom: 24px;"
                >
                  <img src="/img/sign-in/microsoft.svg" width="230px" height="46px" />
                </div>
              </div>
            </div>
            <div
              v-if="connected === false && aliveRan == true"
              class="red-text center-align"
              style="margin-bottom: 25px;"
            >
              <v-icon color="red">mdi-alert</v-icon>
              Can't connect to server, please check your internet connection
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import materialize from "materialize-css"
import * as security from '@/services/SecurityService';

export default {
  data() {
    return {
      clientId: null,
      clientSecret: null,
      ready: false,
      connected: false,
      aliveInterval: null,
      aliveRan: false,
    };
  },
  methods: {
    validClientId() {
      return this.clientId !== null && this.clientId.trim() !== '';
    },
    validClientSecret() {
      return this.clientSecret !== null && this.clientSecret.trim() !== '';
    },
    googleAuthenticationSupported() {
      return security.default().googleAuthenticationSupported();
    },
    localAuthenticationSupported() {
      return security.default().localAuthenticationSupported();
    },
    microsoftAuthenticationSupported() {
      return security.default().microsoftAuthenticationSupported();
    },
    signInLocal() {
      if (this.connected === true) {
        security
          .default()
          .authenticateLocal(this.clientId, this.clientSecret)
          .then(() => {
            if (security.default().hasAnyPermissions()) {
              this.$router.replace('home');
              this.$emit('userChanged');
            } else {
              security.default().signOut(false);
              this.$router.replace('access_denied');
            }
          })
          .catch(() => {
            this.$toast.error('Invalid email or password');
            this.clientSecret = null;
          });
      }
    },
    signInGoogle() {
      if (this.connected === true) {
        security.default().authenticateGoogle();
      }
    },
    signInMicrosoft() {
      if (this.connected === true) {
        security.default().authenticateMicrosoft();
      }
    },
    clearAliveInterval() {
      if (this.aliveInterval !== null) {
        clearInterval(this.aliveInterval);
        this.aliveInterval = null;
      }
    },
    setupAliveInterval() {
      const vm = this;
      if (this.aliveInterval === null) {
        this.aliveInterval = setInterval(() => {
          security
            .default()
            .alive()
            .then(() => {
              vm.connected = true;
            })
            .catch(() => {
              vm.connected = false;
            });
        }, 15000);
      }
    },
  },
  mounted() {
    if (security.default().isValid()) {
      this.$router.replace('home');
      this.$emit('userChanged');
    } else {
      security
        .default()
        .refreshTokens(true)
        .then(() => {
          this.$router.replace('home');
          this.$emit('userChanged');
        })
        .catch(() => {
          this.ready = true;
          security
            .default()
            .alive()
            .then(() => {
              this.connected = true;
              this.aliveRan = true;
            })
            .catch(() => {
              this.connected = false;
              this.aliveRan = true;
            })
            .finally(() => {
              this.setupAliveInterval();
            });
        });
    }
  },
  beforeDestroy() {
    this.clearAliveInterval();
  },
};
</script>

<style scoped>
div.v-card {
  margin-bottom: 5px;
}
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center-align {
  display: flex;
  justify-items: center;
}
.center-align i {
  margin: 0px 10px;
}
.red-text {
  color: rgb(244, 67, 54);
}
</style>
