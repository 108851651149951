<template>
  <v-container class="px-10">
    <v-card flat >
      <v-card-title class="headline">
        Manage Metadata
      </v-card-title>
      <v-card-subtitle>
        Below are all the metadata attributes that can be associated with
        a task specific dataset.
      </v-card-subtitle>
      <v-card-text>
        <v-list three-line dense style="max-height: 70vh" class="overflow-y-auto">
          <div v-for="item in metadataCurationOptions" :key='item.metadata_id'>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>{{type2icon(item.type)}}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.tag.charAt(0).toUpperCase() + item.tag.slice(1)}}
                  <v-chip
                    v-for="option in item.options"
                    :key="option.option"
                    small
                    disabled
                    class="mx-1"
                  >{{option.option}}</v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="openEditMetadata(item)">
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn icon @click="removeMetadatum(item.tag)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              v-bind="attrs"
              v-on="on"
              class="mx-2 "
              @click="addItemModal = true"
            ><v-icon>mdi-plus</v-icon></v-btn>
          </template>
          <span>Add Metadatum</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="addItemModal" max-width='800px'>
      <v-card>
        <v-card-title class="headline">Add Metadata</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newItem.tag"
            label='Tag name'
          />
          <v-text-field
            v-model="newItem.description"
            label='Description'
          />
          <v-select
            v-model="newItem.type"
            :items="types"
            label='Metadata type'
            @change="resfreshNewItem()"
          ></v-select>

          <div v-if="newItem.type === 'categorical'">
            Please add and describe all classes that the metadata item can occupy
            <br/>
            <div v-for="x in newItem.options" :key="x.id">
              <v-row class="pa-0">
                <v-col cols=12 md=3>
                  <v-text-field
                    v-model="x.option"
                    label='Class name'
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="x.description"
                    label='Class description'
                  />
                </v-col>
              </v-row>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color='black'
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="addCategory()"
                ><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
              </template>
              <span>Add Category</span>
            </v-tooltip>
          </div>
          <div v-else-if="newItem.type === 'boolean'">
            Please give optional descriptions of what true and false mean in this context:
            <div v-for="x in newItem.options" :key="x.id">
              <v-row class="pa-0">
                <v-col cols=12 md=3>
                  <v-text-field
                    v-model="x.option"
                    disabled
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="x.description"
                    label='Optional description'
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!readyToAdd"
                outlined
                v-bind="attrs"
                v-on="on"
                class="mx-2 "
                @click="addMetadatum()"
              ><v-icon>mdi-plus</v-icon></v-btn>
            </template>
            <span>Add Metadatum</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-metadata
    :show="showEditMetadata"
    :item="selectedMetadataItem"
    :metadataOptions="metadataCurationOptions"
    @editMetadata="editMetadatum"
    @cancel="showEditMetadata = false"
    />
  </v-container>
</template>

<script>
import axios from 'axios';
import EditMetadata from './EditMetadata.vue';

export default {
  components: {
    EditMetadata,
  },
  data() {
    return {
      showEditMetadata: false,
      selectedMetadataItem: {},
      metadataCurationOptions: null,
      addItemModal: false,
      defaultNewItem: {
        tag: '',
        description: '',
        type: '',
        options: [],
      },
      newItem: null,
      types: ['boolean', 'categorical', 'numeric'],
    };
  },
  created() {
    this.getMetadataOptions();
    this.newItem = Object.assign({}, this.defaultNewItem);
  },
  computed: {
    readyToAdd() {
      const mco = this.metadataCurationOptions;
      const index = mco.findIndex(o => o.tag === this.newItem.tag.toLowerCase());
      return (
        this.newItem.tag !== ''
        && index === -1
      );
    },
  },
  methods: {
    removeMetadatum: async function removeMetadatum(tag) {
      const url = `/api/v2/metadata/remove/${tag}`;
      try {
        await axios.delete(url);
        this.getMetadataOptions();
      } catch (error) {
        console.log(error);
      }
    },
    addMetadatum: async function addMetadatum() {
      const url = '/api/v2/metadata/add';
      try {
        await axios.post(url, {
          tag: this.newItem.tag,
          description: this.newItem.description,
          type: this.newItem.type,
          options: this.newItem.options,
        });
        this.getMetadataOptions();
      } catch (error) {
        console.log(error);
      }

      this.addItemModal = false;
      this.newItem = Object.assign({}, this.defaultNewItem);
    },
    async editMetadatum(item) {
      this.getMetadataOptions();
      const url = '/api/v2/metadata/update';
      try {
        await axios.post(url, item);
        this.getMetadataOptions();
      } catch (error) {
        console.log(error);
      }

      this.showEditMetadata = false;
    },
    openEditMetadata(item) {
      this.showEditMetadata = true;
      this.selectedMetadataItem = item;
    },
    addCategory() {
      this.newItem.options.push({
        id: this.newItem.options.length + 1,
        option: '',
        description: '',
      });
    },
    resfreshNewItem() {
      const type = this.newItem.type;
      this.newItem.options.length = 0;
      if (type === 'boolean') {
        this.newItem.options.push({
          option: 'true',
          description: '',
        });
        this.newItem.options.push({
          option: 'false',
          description: '',
        });
      }
    },
    getMetadataOptions() {
      const path = '/api/v2/metadata';
      try {
        axios.get(path)
          .then((res) => {
            this.metadataCurationOptions = res.data;
          });
      } catch (error) {
        console.log(error);
      }
    },
    type2icon(type) {
      switch (type) {
        case 'boolean':
          return 'mdi-checkbox-marked-outline';
        case 'categorical':
          return 'mdi-check-box-multiple-outline';
        default:
          return 'mdi-tag';
      }
    },
  },
};
</script>

<style>

</style>
