<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700"
    >
      <v-card v-if="subset !== null">
        <v-card-title class="headline">
          Edit Subset
        </v-card-title>
            <v-card-text>
              <v-layout wrap align-center>
                <v-flex xs12 sm6 d-flex>
                  <v-text-field
                    v-model="subset.subset_name"
                    outlined
                    label="Subset Name"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 d-flex>
                  <v-text-field
                    :value="subset.project_name"
                    outlined
                    label="Project"
                    class="ml-2"
                    :disabled="true"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                      v-model="subset.description"
                      outlined
                      label="Subset Description">
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                 <v-select
                    :items="allMetadataOptions"
                    v-model="selectedMetadataOptions"
                    outlined
                    label="Headers"
                    multiple
                    clearable
                    small-chips
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-select
                   :items="fileTypes"
                    v-model="subset.file_type"
                    outlined
                    label="File Type"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="close"
          >
            Cancel
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disable()"
            @click="update"
          >
           Update
           <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    selectedMetadata: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
    allMetadataOptions: {
      type: Array,
      default: () => [],
    },
    subsets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      selectedMetadataOptions: [],
      fileTypes: ['filled_image', 'template_image'],
      subset: null,
      subsetName: '',
      oldName: '',
    };
  },
  methods: {
    update() {
      const updateFields = {
        old_subset_name: this.oldName,
        subset_name: this.subset.subset_name,
        description: this.subset.description,
        file_type: this.subset.file_type,
        metadata: this.selectedMetadataOptions,
      };
      this.$emit('updateSubset', updateFields);
      this.dialog = false;
    },
    close() {
      this.dialog = false;
      this.subset = JSON.parse(JSON.stringify(this.item));
    },
    disable() {
      const sm = this.$props.selectedMetadata.concat().sort().join(',');
      const smo = this.selectedMetadataOptions.concat().sort().join(',');
      /* eslint-disable */
      if (JSON.stringify(this.subset) === JSON.stringify(this.$props.item) && (sm === smo)) { return true; }
      if (this.subset.subset_name !== this.$props.item.subset_name) {
        if (this.$props.subsets.includes(this.subset.subset_name)) { return true; }
      }
      if (this.subset.subset_name === '') { return true; }
      if (this.subset.project_name === '') { return true; }
      if (this.subset.description === '') { return true; }
      if (this.subset.file_type === '') { return true; }
      if (this.selectedMetadataOptions.length === 0) { return true; }
      return false;
    },
  },
  watch: {
    selectedMetadata() {
      this.selectedMetadataOptions = this.selectedMetadata;
      this.dialog = true;
    },
    item() {
      this.subset = JSON.parse(JSON.stringify(this.item));
      this.oldName = this.$props.item.subset_name;
    },
  },
};
</script>
