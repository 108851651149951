var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-10"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Manage Metadata ")]),_c('v-card-subtitle',[_vm._v(" Below are all the metadata attributes that can be associated with a task specific dataset. ")]),_c('v-card-text',[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"70vh"},attrs:{"three-line":"","dense":""}},_vm._l((_vm.metadataCurationOptions),function(item){return _c('div',{key:item.metadata_id},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(_vm._s(_vm.type2icon(item.type)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.tag.charAt(0).toUpperCase() + item.tag.slice(1))+" "),_vm._l((item.options),function(option){return _c('v-chip',{key:option.option,staticClass:"mx-1",attrs:{"small":"","disabled":""}},[_vm._v(_vm._s(option.option))])})],2),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.description))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openEditMetadata(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeMetadatum(item.tag)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)],1),_c('v-divider')],1)}),0)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 ",attrs:{"outlined":""},on:{"click":function($event){_vm.addItemModal = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add Metadatum")])])],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.addItemModal),callback:function ($$v) {_vm.addItemModal=$$v},expression:"addItemModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Add Metadata")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Tag name"},model:{value:(_vm.newItem.tag),callback:function ($$v) {_vm.$set(_vm.newItem, "tag", $$v)},expression:"newItem.tag"}}),_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.newItem.description),callback:function ($$v) {_vm.$set(_vm.newItem, "description", $$v)},expression:"newItem.description"}}),_c('v-select',{attrs:{"items":_vm.types,"label":"Metadata type"},on:{"change":function($event){return _vm.resfreshNewItem()}},model:{value:(_vm.newItem.type),callback:function ($$v) {_vm.$set(_vm.newItem, "type", $$v)},expression:"newItem.type"}}),(_vm.newItem.type === 'categorical')?_c('div',[_vm._v(" Please add and describe all classes that the metadata item can occupy "),_c('br'),_vm._l((_vm.newItem.options),function(x){return _c('div',{key:x.id},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Class name"},model:{value:(x.option),callback:function ($$v) {_vm.$set(x, "option", $$v)},expression:"x.option"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Class description"},model:{value:(x.description),callback:function ($$v) {_vm.$set(x, "description", $$v)},expression:"x.description"}})],1)],1)],1)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","icon":""},on:{"click":function($event){return _vm.addCategory()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,false,1375712755)},[_c('span',[_vm._v("Add Category")])])],2):(_vm.newItem.type === 'boolean')?_c('div',[_vm._v(" Please give optional descriptions of what true and false mean in this context: "),_vm._l((_vm.newItem.options),function(x){return _c('div',{key:x.id},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"disabled":""},model:{value:(x.option),callback:function ($$v) {_vm.$set(x, "option", $$v)},expression:"x.option"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Optional description"},model:{value:(x.description),callback:function ($$v) {_vm.$set(x, "description", $$v)},expression:"x.description"}})],1)],1)],1)})],2):_vm._e()],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 ",attrs:{"disabled":!_vm.readyToAdd,"outlined":""},on:{"click":function($event){return _vm.addMetadatum()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add Metadatum")])])],1)],1)],1),_c('edit-metadata',{attrs:{"show":_vm.showEditMetadata,"item":_vm.selectedMetadataItem,"metadataOptions":_vm.metadataCurationOptions},on:{"editMetadata":_vm.editMetadatum,"cancel":function($event){_vm.showEditMetadata = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }