<template>
  <div class="dashboard">
    <v-card min-width="40%">
      <v-card-title>Users</v-card-title>
      <v-card-text>
        <v-text-field
          type="text"
          v-model="email"
          @keyup.enter="listUsers()"
          ref="email"
          label="Email"
        />
        <v-btn @click="listUsers()">
          Search
          <v-icon right>mdi-account-search</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="addUser()">
          Add User
          <v-icon right>mdi-account-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card min-width="95%">
      <v-data-table :headers="headers" :items="users">
        <template v-slot:item.picture="{ item }">
          <img :src="image(item.picture)" :class="imageClass(item.picture)"/>
        </template>
        <template v-slot:item.active="{ item }">
          <v-switch v-show="item.active !== null && item.active !== undefined"
            v-model="item.active" label="Enabled"
            @change="changeUserStatus(item.email, item.active)"/>
          <v-switch v-show="item.active === null || item.active === undefined"
            disabled
            label="Registration Incomplete"/>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            v-if="allowsReset()"
            :disabled="item.type === 'federated'"
            @click="resetPassword(item.email)">
            Reset
            <v-icon right>mdi-lock-reset</v-icon>
          </v-btn>
          <v-btn @click="editUserRoles(item.email)">
            Roles
            <v-icon right>mdi-shield-account</v-icon>
          </v-btn>
          <v-btn color="error" @click="openDeleteWarning(item.email)">
            Delete
            <v-icon right>mdi-account-cancel</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="deleteWarning"
      persistent
      max-width="340px">
      <v-card>
        <v-card-title class="headline">Warning!</v-card-title>
        <v-card-text>
          <p>Are you sure?</p>
          <p>This action is permanent</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error"
            @click="deleteWarning = false">
            No
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            @click="deleteUser(userToBeDeleted)">
            Yes
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import * as security from '@/services/SecurityService';

export default {
  data() {
    return {
      email: '',
      newEmail: '',
      headers: [
        {
          text: 'Image',
          value: 'picture',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'First Name',
          value: 'first_name',
        },
        {
          text: 'Last Name',
          value: 'last_name',
        },
        {
          text: 'Account',
          value: 'active',
          width: '250px',
        },
        {
          text: 'Actions',
          value: 'action',
          sortable: false,
          width: '360px',
        },
      ],
      users: [],
      userToBeDeleted: '',
      deleteWarning: false,
    };
  },
  methods: {
    allowsReset() {
      return security.default().localAuthenticationSupported();
    },
    image(value) {
      if (value === null || value === undefined) {
        return '/img/user/unknown.png';
      }
      if (value === 'microsoft://') {
        return '/img/user/microsoft.svg';
      }
      return value;
    },
    imageClass(value) {
      if (value === null || value === undefined) {
        return 'shot';
      }
      if (value === 'microsoft://') {
        return 'no-shot';
      }
      return 'shot';
    },
    addUser() {
      this.$router.push('/settings/user/new');
    },
    async deleteUser(email) {
      try {
        await axios.delete(`/api/v2/user/${encodeURIComponent(email)}`);
        this.$toast.success('User deleted');
        this.userToBeDeleted = '';
        this.deleteWarning = false;
        this.listUsers();
      } catch (ex) {
        this.$toast.error(ex.response.data);
      }
    },
    async listUsers() {
      const response = await axios.get(`/api/v2/user?email=${encodeURIComponent(this.email)}`);
      this.users = response.data;
    },
    editUserRoles(email) {
      this.$router.push({ name: 'UserRoleManagement', params: { email } });
    },
    async resetPassword(email) {
      this.$router.push({ name: 'ResetPassword', params: { email } });
    },
    async changeUserStatus(email, enable) {
      try {
        await axios.post(`/api/v2/user/${encodeURIComponent(email)}/change_status`, { enable });
        this.$toast.success(enable ? 'User enabled' : 'User disabled');
      } catch (ex) {
        this.$toast.error(ex.response.data);
      }
    },
    openDeleteWarning(user) {
      this.userToBeDeleted = user;
      this.deleteWarning = true;
    },
  },
  mounted() {
    this.listUsers();
    this.$refs.email.focus();
  },
};
</script>
<style scoped>
div.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shot {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.no-shot {
  width: 32px;
  height: 32px;
}
div.dashboard div.v-card__text {
  display: flex;
  flex-direction: row;
}
div.v-card__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-dialog div.v-card {
  margin-bottom: 0px;
}
div.v-dialog div.v-card div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.v-card {
  margin-bottom: 10px;
}
button.v-btn {
  margin-left: 1px;
  margin-right: 1px;
}
td.text-start {
  height: 32px;
}
tr {
  height: 32px;
}
</style>
