<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title class="headline">
          API Client Details
        </v-card-title>
        <v-card-text>
          <v-layout wrap align-center>
            <v-flex xs12 sm12 d-flex>
              <v-text-field
                :value="clients.client_id"
                outlined
                label="Client ID">
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm12 d-flex>
              <v-text-field
                :value="clients.client_secret"
                outlined
                label="Client Secret">
              </v-text-field>
            </v-flex>
          </v-layout>
          <!-- eslint-disable max-len -->
          <div class="red--text mt-2">
            Please ensure that you copy the following 'Client Secret' as you will not be able to view it later.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="close"
          >
            OK
            <v-icon right="">mdi mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      clients: {},
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  watch: {
    client() {
      this.clients = this.client;
      this.dialog = true;
    },
  },
};
</script>
