<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title>Bulk Action: Label</v-card-title>
      <v-card-text>
          <v-text-field
            label="Field"
            v-model="targetField"
            hint="Where to store the results"/>
          <v-autocomplete
            label="File Type"
            dense
            filled
            v-model="item.file_description"
            :items="fileTypes"
            hint="File to label"/>
          <v-autocomplete
            label="Dataset"
            dense
            filled
            v-model="item.dataset"
            :items="datasets"
            hint="Dataset in Labeller to integrate to"/>
          <v-autocomplete
            label="Model"
            dense
            filled
            v-model="item.model"
            :items="models"
            hint="Workflow selector in Labeller, use text if unsure"/>
          <v-autocomplete
            label="TaskType"
            dense
            filled
            v-model="item.task_type"
            :items="taskTypes"
            hint="The task type"/>
          <v-autocomplete
            v-if ="item.task_type === 'select'"
            label="Candidates Field:"
            dense
            filled
            v-model="item.products_field"
            :items="fields"
            hint="The task type"/>
          <v-combobox
            v-if="item.task_type === 'curation'"
            v-model="selectedTags"
            :items="tags"
            label="select or type in tags with no spaces"
            multiple
            chips>
          </v-combobox>
          <v-text-field
            label="Hint"
            hint="Instruction to display in Labeller"
            v-model="item.hint"/>
          <v-checkbox
            v-if="item.task_type !== 'curation'"
            label="Binary Field?"
            v-model="item.is_binary"/>
          <v-text-field v-show="!item.is_binary"
            label="Validation"
            hint="Regex to use for field validation"
            v-model="item.validation"/>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="cancel"
          color="error"
        >
          Cancel
          <v-icon right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="confirm"
          color="primary"
          :disabled="!isValid"
        >
          OK
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      targetField: null,
      item: {
        file_description: 'filled_image',
        dataset: 'Curation',
        model: 'text',
        task_type: 'curation',
        products_field: 'product_ids',
        hint: null,
        is_binary: false,
        validation: null,
      },
      models: ['alphanumeric', 'checkbox', 'currency', 'numeric', 'signature', 'text'],
      taskTypes: ['tag', 'select', 'curation', 'regions'],
      showing: false,
      tags: ['bad-crop', 'watermark', 'strike-through', 'corrected', 'stamp', 'creeping', 'noisy', 'multiline'],
      selectedTags: [],
    };
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    fileType: {
      type: String,
      required: false,
    },
    fileTypes: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
    },
    fields: {
      type: Array,
      required: false,
    },
  },
  computed: {
    isValid() {
      return this.targetField !== null && this.targetField !== '' && this.showing === true;
    },
  },
  methods: {
    confirm() {
      this.showing = false;
      const item = {
        field: this.targetField,
        file_description: this.item.file_description,
        dataset: this.item.dataset,
        model: this.item.model,
        task_type: this.item.task_type,
        products_field: this.item.products_field,
        hint: this.item.hint === '' ? null : this.item.hint,
        is_binary: this.item.model === 'checkbox' || this.item.model === 'signature',
        validation: this.item.validation,
        tags: this.selectedTags,
      };
      this.$emit('ok', this.targetField, item);
    },
    cancel() {
      this.showing = false;
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      deep: true,
      handler(show) {
        this.showing = show;
      },
    },
    fileType: {
      deep: true,
      handler(fileType) {
        this.item.file_description = fileType;
      },
    },
  },
  mounted() {
    this.item.file_description = this.fileType;
  },
};
</script>
<style scoped>
.pad {
  margin-bottom: 15px;
}
div.v-card__text {
  display: flex;
  flex-direction: column;
  align-items: left;
}
div.v-card__text div {
  margin: 5px;
}
</style>
