<template>
  <v-container>
    <v-card>
      <v-card-title style="background-color:#faca28">
        History
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="history_data"
        :search="search"
      >

      <template v-slot:item.new_val = '{ item }'>
        <DataItemComponent title="New Value" :history_datum="item.new_val"/>
      </template>

      <template v-slot:item.old_val = '{ item }'>
        <DataItemComponent title="Old Value" :history_datum="item.old_val"/>
      </template>

      </v-data-table>

    </v-card>
    <DataItemComponent></DataItemComponent>
  </v-container>
</template>

<script>
import axios from 'axios';
import DataItemComponent from './common/DataItemComponent.vue';

export default {
  name: 'History',
  components: {
    DataItemComponent,
  },
  data() {
    return {
      search: '',
      no_data_left: false,
      history_data: [],
      headers: [
        { text: 'Time Stamp', value: 'tstamp' },
        { text: 'Operation', value: 'operation' },
        { text: 'User', value: 'who' },
        { text: 'Old Value', value: 'old_val' },
        { text: 'New Value', value: 'new_val' },
      ],
      limit: 100,
      offset: 0,
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    getHistory: async function getHistory() {
      console.group('Method: getHistory()');
      const path = 'api/history';

      // TODO: allow user to set params from the fronted
      try {
        const res = await axios.get(path, {
          params: {
            limit: this.limit,
            offset: this.offset,
          },
        });
        this.history_data = res.data;

        console.log(this.history_data);

        if (this.history_data.length === 0) this.no_data_left = true;
        else this.no_data_left = false;
      } catch (error) {
        console.log(error);
      }
      console.groupEnd();
    },
  },
};
</script>
