import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    themes: {
      light: {
        primary: '#607d8b',
        secondary: '#673Ab7',
        isaziyellow: '#FAC21A',
        isaziblue: '#6abbc5',
        isazired: '#E42152',
        accent: '#f50057',
        error: '#b71c1c',
      },
    },
  },
});
