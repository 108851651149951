/* eslint semi: 0 */  // --> OFF
/* eslint space-before-function-paren: 0 */  // --> OFF
/* eslint arrow-body-style: 0 */  // --> OFF
/* eslint prefer-const: 0 */  // --> OFF
/* eslint no-shadow: 0 */  // --> OFF
/* eslint no-var: 0 */  // --> OFF
/* eslint vars-on-top: 0 */  // --> OFF
/* eslint operator-linebreak: 0 */  // --> OFF
/* eslint max-len: 0 */  // --> OFF
/* eslint no-else-return: 0 */  // --> OFF
/* eslint no-restricted-syntax: 0 */  // --> OFF
/* eslint guard-for-in: 0 */  // --> OFF
/* eslint no-plusplus: 0 */  // --> OFF
/* eslint prefer-template: 0 */  // --> OFF
/* eslint class-methods-use-this: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
/* eslint indent: 0 */  // --> OFF
/* eslint comma-dangle: 0 */  // --> OFF
/* eslint quote-props: 0 */  // --> OFF
/* eslint consistent-return: 0 */  // --> OFF
/* eslint no-param-reassign: 0 */  // --> OFF
/* eslint no-multiple-empty-lines: 0 */  // --> OFF
/* eslint no-multi-spaces: 0 */  // --> OFF



let axios = require('axios')
var crypto = require('crypto-js')

function getParameterByName (name, url) {
  if (!url) url = window.location.href
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  var results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function getQueryParameters (clientId, callbackUrl, state) {
  var params = 'client_id=' + clientId + '&'
  params += 'response_type=code&'
  params += 'scope=' + encodeURIComponent('openid profile email') + '&'
  params += 'redirect_uri=' + encodeURIComponent(callbackUrl) + '&'
  params += 'state=' + state + '&'
  params += 'nonce=' + crypto.lib.WordArray.random(32) + '&'
  params += 'access_type=offline&'
  params += 'prompt=consent'
  return params
}

function getBaseURL () {
  return window.location.protocol + '//' + window.location.hostname + (window.location.port !== null && window.location.port !== undefined && window.location.port !== '' ? ':' + window.location.port : '')
}

export class SecurityService {
  constructor (options) {
    this.domainConst = 'oidc.domain'
    this.stateConst = 'oidc.state'
    this.reauthenticateConst = 'oidc.reauthenticate'
    this.profileConst = 'oidc.profile'
    this.affordanceConst = 300
    this.options = options
  }

  getCallbackURL () {
    return getBaseURL() + this.options.callbackUrl
  }

  getLogoutURL () {
    return getBaseURL() + this.options.logoutUrl
  }

  mask (str) {
    str = btoa(str)
    var a = ''
    var b = ''
    for (var i = 0; i < str.length / 2; i++) {
      a += str[i * 2]
      b += str[i * 2 + 1]
    }
    str = a + b
    return str.split('=').join('')
  }

  authenticateLocal (clientId, clientSecret) {
    if (!this.localAuthenticationSupported()) {
      return Promise.resolve()
    }
    let request = {
      client_id: 'local.' + clientId,
      client_secret: clientSecret,
      grant_type: 'client_credentials',
      audience: this.options.local.audience
    }
    if (this.maskSupported()) {
      request.client_secret = this.mask(request.client_secret)
      request.hmac = true
    }
    return axios.post(this.options.authorizeUrl, request)
      .then((response) => {
        if (this.options.useCookies === true) {
          document.cookie = `${this.reauthenticateConst}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
        } else {
          localStorage.removeItem(this.reauthenticateConst)
        }
        return response
      })
      .then(() => {
        return axios.get(this.options.userInfoUrl)
          .then((response) => {
            if (this.options.useCookies === true) {
              document.cookie = `profile=${JSON.stringify(response.data)}`
            } else {
              sessionStorage.setItem(this.profileConst, JSON.stringify(response.data))
            }
          })
      })
      .then(() => {
        if (this.options.useCookies === true) {
          document.cookie = `${this.reauthenticateConst}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
        } else {
          localStorage.removeItem(this.reauthenticateConst)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error('AUTHENTICATION UNSUCCESSFUL')
        // eslint-disable-next-line
        console.error('DETAIL:', error.response.status, error.response.data)
        throw error
      })
  }

  authenticateMicrosoft () {
    if (!this.microsoftAuthenticationSupported()) {
      return Promise.resolve()
    }
    let state = crypto.SHA256(crypto.lib.WordArray.random(1024)).toString(crypto.enc.base64)
    if (this.options.useCookies === true) {
      document.cookie = `${this.stateConst}=${state};`
      document.cookie = `${this.domainConst}=microsoft;`
    } else {
      sessionStorage.setItem(this.stateConst, state)
      sessionStorage.setItem(this.domainConst, 'microsoft')
    }
    var url = 'https://login.microsoftonline.com/' + this.options.microsoft.applicationId + '/oauth2/v2.0/authorize?'
    url += getQueryParameters(this.options.microsoft.clientId, this.getCallbackURL(), state)
    window.location.href = url
  }

  authenticateGoogle () {
    if (!this.googleAuthenticationSupported()) {
      return Promise.resolve()
    }
    let state = crypto.SHA256(crypto.lib.WordArray.random(1024)).toString(crypto.enc.base64)
    if (this.options.useCookies === true) {
      document.cookie = `${this.stateConst}=${state};`
      document.cookie = `${this.domainConst}=google;`
    } else {
      sessionStorage.setItem(this.stateConst, state)
      sessionStorage.setItem(this.domainConst, 'google')
    }
    var url = 'https://accounts.google.com/o/oauth2/v2/auth?'
    url += getQueryParameters(this.options.google.clientId, this.getCallbackURL(), state)
    window.location.href = url
  }

  alive () {
    return axios
      .get(this.options.aliveUrl)
  }

  authorize () {
    let state = getParameterByName('state')
    let code = getParameterByName('code')
    var savedState = null
    var domain = null
    if (this.options.useCookies === true) {
      savedState = this.getCookie(this.stateConst)
      domain = this.getCookie(this.domainConst)
      document.cookie = `${this.stateConst}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
      document.cookie = `${this.domainConst}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
      document.cookie = `profile=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    } else {
      savedState = sessionStorage.getItem(this.stateConst)
      domain = sessionStorage.getItem(this.domainConst)
      sessionStorage.removeItem(this.stateConst)
      sessionStorage.removeItem(this.domainConst)
      sessionStorage.removeItem(this.profileConst)
    }
    if (code === null) {
      return Promise.reject(new Error('user_cancelled'))
    }
    if (savedState !== state) {
      return Promise.reject(new Error('man_in_the_middle'))
    }
    return axios
    .post(this.options.authorizeUrl, {
      'grant_type': 'authorization_code',
      'code': code,
      'domain': domain,
      'redirect_uri': this.getCallbackURL()
    })
    .then((response) => {
      if (this.options.useCookies === true) {
        document.cookie = `${this.reauthenticateConst}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
      } else {
        localStorage.removeItem(this.reauthenticateConst)
      }
      return response
    })
    .then(() => {
      return axios.get(this.options.userInfoUrl)
        .then((response) => {
          if (this.options.useCookies === true) {
            document.cookie = `profile=${JSON.stringify(response.data)}`
          } else {
            sessionStorage.setItem(this.profileConst, JSON.stringify(response.data))
          }
        })
    })
    .then(() => {
      if (this.options.useCookies === true) {
        document.cookie = `${this.reauthenticateConst}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
      } else {
        localStorage.removeItem(this.reauthenticateConst)
      }
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error('AUTHENTICATION UNSUCCESSFUL')
      // eslint-disable-next-line
      console.error('DETAIL:', error.response.status, error.response.data)
      throw error
    })
  }

  isValid () {
    var reauthenticate = null
    if (this.options.useCookies === true) {
      reauthenticate = this.getCookie(this.reauthenticateConst)
    } else {
      reauthenticate = localStorage.getItem(this.reauthenticateConst)
    }
    if (reauthenticate === 'true') {
      return false
    }
    let user = this.getUser()
    return (user !== null)
  }

  refreshTokens (force = false) {
    var reauthenticate = null
    if (this.options.useCookies === true) {
      reauthenticate = this.getCookie(this.reauthenticateConst)
    } else {
      reauthenticate = localStorage.getItem(this.reauthenticateConst)
    }
    if (reauthenticate === 'true') {
      return Promise.reject(new Error('Must reauthenticate'))
    }
    let user = this.getUser()
    if (force || user === null) {
      // eslint-disable-next-line
      console.info('Refreshing access tokens...')
      return axios
      .post(this.options.refreshUrl, {
        'grant_type': 'refresh_token'
      })
      .then(() => {
        return axios.get(this.options.userInfoUrl)
          .then((response) => {
            if (this.options.useCookies === true) {
              document.cookie = `profile=${JSON.stringify(response.data)};`
            } else {
              sessionStorage.setItem(this.profileConst, JSON.stringify(response.data))
            }
          })
      })
    }
    return Promise.resolve()
  }

  signOut (navigate = true) {
    return axios.post(this.options.revokeUrl, {})
      .finally(() => {
        sessionStorage.clear()
        localStorage.clear()
        if (this.options.useCookies === true) {
          document.cookie = `${this.reauthenticateConst}=true;`
          document.cookie = `profile=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
        } else {
          localStorage.setItem(this.reauthenticateConst, 'true')
        }
        if (navigate === true && window.location.href !== this.getLogoutURL()) {
          window.location.href = this.getLogoutURL()
        }
      })
  }

  getCookie (cname) {
    let name = cname + '='
    let ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  getUser () {
    if (!this.options) { // This can happen when the function is called before the context is loaded https://github.com/vuejs/vue-router/issues/1144
      return null
    }

    var profile = this.options.useCookies === true ? this.getCookie('profile') : sessionStorage.getItem(this.profileConst)
    if (profile === null || profile.trim() === '') {
      return null
    }
    profile = JSON.parse(profile)
    let nowish = Math.round(new Date().getTime() / 1000) + this.affordanceConst
    if (profile !== null && profile !== undefined && profile.exp > nowish) {
      if (profile.picture === 'microsoft://') {
        profile.picture = this.options.microsoft.pictureUrl
      }
      return profile
    }
    return null
  }

  hasAnyPermissions () {
    let user = this.getUser()
    if (user === null) {
      return false
    }
    var i = 0
    // eslint-disable-next-line
    for (let p in user.permissions) {
      i++
    }
    return i > 0
  }

  hasPermission (permission, tenant = null, unit = null) {
    let user = this.getUser()
    if (user === null) {
      return false
    }
    let userPermissions = user.permissions
    for (let t in userPermissions) {
      for (let u in userPermissions[t]) {
        for (let p of userPermissions[t][u]) {
          if ((tenant === null || t === 'all') && (unit === null || u === 'all') && permission === p) {
            return true
          } else if ((tenant === null || t === 'all') && (unit === u || u === 'all') && permission === p) {
            return true
          } else if ((tenant === t || t === 'all') && (unit === u || u === 'all') && permission === p) {
            return true
          }
        }
      }
    }
    return false
  }

  googleAuthenticationSupported () {
    return this.options.google !== null && this.options.google !== undefined
  }

  localAuthenticationSupported () {
    return this.options.local !== null && this.options.local !== undefined
  }

  maskSupported() {
    return this.options.local !== null && this.options.local !== undefined && this.options.local.masked === true
  }

  microsoftAuthenticationSupported () {
    return this.options.microsoft !== null && this.options.microsoft !== undefined
  }

  axiosGuard (config) {
    return new Promise((resolve, reject) => {
      if (config.url === this.options.authorizeUrl ||
          config.url === this.options.revokeUrl ||
          config.url === this.options.refreshUrl ||
          config.url === this.options.aliveUrl ||
          config.url === this.options.userInfoUrl) {
        resolve(config)
        return
      }
      if (this.isValid()) {
        resolve(config)
        return
      }
      this.refreshTokens(true)
        .then(() => {
          resolve(config)
        })
        .catch((error) => {
          this.signOut(true)
          reject(error)
        })
    })
  }

  routeGuard(permission, tenant = null, unit = null, navigateUrl = '/access_denied') {
    if (!this.hasPermission(permission, tenant, unit)) {
      window.location.href = navigateUrl
      return false
    }
    return true
  }
}

var opts = null
async function options (options = null) {
  if (opts !== null) {
    return
  }
  if (options === null) {
    let response = await axios.get('/config.json')
    opts = response.data
  } else {
    opts = options
  }
  axios.interceptors.request.use((config) => {
    return new SecurityService(opts)
      .axiosGuard(config)
  })
}

export async function context () {
  await options()
  return new SecurityService(opts)
}

export default function () {
  return new SecurityService(opts)
}

/* eslint no-use-before-define: 2 */
