import Vue from 'vue';
import VueRouter from 'vue-router';

// Main views
import Welcome from '../components/Welcome.vue';
import History from '../components/History.vue';

// Explore
import ExploreData from '@/components/explore/ExploreData.vue';
import ExploreSubsets from '@/components/explore/ExploreSubsets.vue';

// Authenticate
import SignIn from '../components/authenticate/SignIn.vue';
import Callback from '../components/authenticate/Callback.vue';
import SignOut from '../components/authenticate/SignOut.vue';
import AccessDenied from '../components/authenticate/AccessDenied.vue';
import UserCancelled from '../components/authenticate/UserCancelled.vue';
import TechnicalError from '../components/authenticate/TechnicalError.vue';
import TokenExpired from '../components/authenticate/TokenExpired.vue';

// Settings
import ResetPassword from '../components/authorize/ResetPassword.vue';
import UserDetails from '../components/authorize/UserDetails.vue';
import UserManagement from '../components/authorize/UserManagement.vue';
import UserRoleManagement from '../components/authorize/UserRoleManagement.vue';
import MetadataManagement from '../components/settings/MetadataManagement.vue';
// eslint-disable-next-line import/no-unresolved
import ManageProjects from '../components/settings/ManageProjects.vue';
import ManageSubsets from '../components/settings/ManageSubsets.vue';
import ManageScanners from '../components/settings/ManageScanners.vue';
import APIClientManagement from '../components/settings/APIClientManagement.vue';

import * as security from '@/services/SecurityService';

Vue.use(VueRouter);


async function contextCheck(next) {
  await security.context();
  next();
}

async function securityCheck(next, to) {
  const context = await security.context();
  if (!context.isValid()) {
    try {
      await context.refreshTokens(true);
    } catch {
      window.location = '/';
    }
  }
  let permissions = to.matched
    .filter(x => x.meta.permission !== undefined && x.meta.permission !== null)
    .map(x => x.meta.permission);
  if (permissions.length === 0) {
    next();
    return;
  }
  permissions = permissions.filter(x => context.hasPermission(x));
  if (permissions.length !== 0) {
    next();
  } else {
    window.location = '/home';
  }
}

function unvalidatedRoute(to, from, next) {
  contextCheck(next);
}

function validatedRoute(to, from, next) {
  securityCheck(next, to);
}

const routes = [
  {
    path: '/',
    name: 'signin',
    component: SignIn,
    beforeEnter: unvalidatedRoute,
    meta: { title: 'Sign In' },
  },
  {
    path: '/callback',
    name: 'callback',
    component: Callback,
    beforeEnter: unvalidatedRoute,
    meta: { title: 'Sign In' },
  },
  {
    path: '/signout',
    name: 'signout',
    component: SignOut,
    beforeEnter: unvalidatedRoute,
    meta: { title: 'Sign Out' },
  },
  {
    path: '/access_denied',
    name: 'access denied',
    component: AccessDenied,
    beforeEnter: unvalidatedRoute,
    meta: { title: 'Access Denied' },
  },
  {
    path: '/technical_error',
    name: 'technical error',
    component: TechnicalError,
    beforeEnter: unvalidatedRoute,
    meta: { title: 'Technical Error' },
  },
  {
    path: '/token_expired',
    name: 'token expired',
    component: TokenExpired,
    beforeEnter: unvalidatedRoute,
    meta: { title: 'Token Expired' },
  },
  {
    path: '/user_cancelled',
    name: 'user cancelled',
    component: UserCancelled,
    beforeEnter: unvalidatedRoute,
    meta: { title: 'User Cancelled' },
  },
  {
    path: '/home',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database' },
  },
  {
    path: '/explore/data',
    name: 'Explore Data',
    component: ExploreData,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Explore Data' },
  },
  {
    path: '/explore/subsets',
    name: 'Explore Subsets',
    component: ExploreSubsets,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Explore Subsets' },
  },
  {
    path: '/settings/users',
    name: 'UserManagement',
    component: UserManagement,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings - Users', permission: 'manage' },
  },
  {
    path: '/settings/user/:option',
    name: 'UserDetails',
    component: UserDetails,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings - Users', permission: 'manage' },
  },
  {
    path: '/settings/user/reset_password/:email',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings - Users', permission: 'manage' },
  },
  {
    path: '/settings/user/roles/:email',
    name: 'UserRoleManagement',
    component: UserRoleManagement,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings - Users', permission: 'manage' },
  },
  {
    path: '/settings/projects',
    name: 'ManageProjects',
    component: ManageProjects,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings - Manage Projects' },
  },
  {
    path: '/settings/subsets',
    name: 'ManageSubsets',
    component: ManageSubsets,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings - Manage Subsets' },
  },
  {
    path: '/settings/metadata',
    name: 'MetadataManagement',
    component: MetadataManagement,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings' },
  },
  {
    path: '/settings/scanners',
    name: 'ManageScanners',
    component: ManageScanners,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings - Manage Scanners' },
  },
  {
    path: '/settings/clients',
    name: 'APIClientManagement',
    component: APIClientManagement,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - Settings - API Clients', permission: 'manage' },
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - History' },
  },
  {
    path: '/user/details/:option',
    name: 'CurrentUserDetails',
    component: UserDetails,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - User - User Details' },
  },
  {
    path: '/user/reset_password/:email',
    name: 'ResetUserPassword',
    component: ResetPassword,
    beforeEnter: validatedRoute,
    meta: { title: 'Curated Database - User - Reset Password' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
