<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row justify="end" class="mr-5 mt-2">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            create subset
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="headline">
          Create A Subset
        </v-card-title>
            <v-card-text>
              <v-layout wrap align-center>
                <v-flex xs12 sm6 d-flex>
                  <v-text-field
                      v-model="subsetName"
                      outlined
                      label="Subset Name">
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 d-flex>
                  <v-select
                    :items="projects"
                    v-model="projectName"
                    outlined
                    label="Project Name"
                    class="ml-2"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-text-field
                    v-model="description"
                    outlined
                    label="Subset Description">
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                 <v-select
                    :items="metadataOptions"
                    v-model="metadata"
                    outlined
                    label="Headers"
                    multiple
                    clearable
                    small-chips
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 d-flex>
                  <v-select
                    :items="fileTypes"
                    v-model="fileType"
                    outlined
                    label="File Type"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="dialog = false"
          >
            Cancel
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disable()"
            @click="close"
          >
           Create
           <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    metadataOptions: {
      type: Array,
      default: () => [],
    },
    subsets: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      subsetName: '',
      projectName: '',
      description: '',
      metadata: [],
      fileType: '',
      fileTypes: ['filled_image', 'template_image', 'labeller', 'labeller_segment', 'labeller_template', 'labeller_segment_template'],
    };
  },
  methods: {
    close() {
      const subsetFields = {
        subset_name: this.subsetName,
        project_name: this.projectName,
        description: this.description,
        metadata: this.metadata,
        file_type: this.fileType,
      };
      this.$emit('createSubset', subsetFields);
      this.dialog = false;
      this.clear();
    },
    clear() {
      this.subsetName = '';
      this.projectName = '';
      this.description = '';
      this.metadata = [];
      this.fileType = '';
    },
    disable() {
      if (this.$props.subsets.includes(this.subsetName)) { return true; }
      if (this.subsetName === '') { return true; }
      if (this.projectName === '') { return true; }
      if (this.description === '') { return true; }
      if (this.fileType === '') { return true; }
      if (this.metadata.length === 0) { return true; }
      return false;
    },
  },
};
</script>
